#portfolio {
  padding: 100px 40px;
  position: relative;
  background: #f5f5f5;

  &.portfolio-three {
    padding: 100px 0; }

  .title_right {
    right: 0; }

  .switch_right {
    right: 6%; } }

.portfolio-filter, .portfolio-filter-two {
  margin: 30px 0 0 0;
  padding-left: 15px;
  list-style: none;
  margin-bottom: 30px;

  li {
    display: inline-block;
    margin-right: 15px;

    a {
      padding: 10px 22px;
      border: 1px solid #000;
      color: #000;
      font-size: 13px;
      text-transform: uppercase;
      line-height: 10px;


      &:hover, &:focus, &.active {
        color: #FFF;
        background: $color_theme;
        border-color: transparent;
        text-decoration: none; } } } }

.gp-portfolio-gallery, .gp-portfolio-gallery-two {
  margin-bottom: 40px; }

.gp-portfolio-item {
  background: $color_theme;
  position: relative;
  overflow: hidden;
  margin: 4px 0.2%;
  height: auto;
  text-align: center;
  cursor: pointer;
  float: left;

  img {
    width: 100%;
    opacity: 0.95;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: scale3d(1.05,1.05,1);
    transform: scale3d(1.05,1.05,1); }

  &.width-2-1 {
    width: 50%; }

  .inner {
    position: relative;
    overflow: hidden;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255,255,255,0.5);
      content: '';
      -webkit-transition: -webkit-transform 0.6s;
      transition: transform 0.6s;
      -webkit-transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,-100%,0);
      transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,-100%,0); } }

  .portfolio-overlay {
    opacity: 0;
    position: relative;
    transform: scale(1.5);
    transition: all 0.3s ease-in-out;

    .content {
      position: absolute;
      left: 50%;
      bottom: 30px;
      transform: translateX(-50%);
      width: 100%;


      h4 {
        color: #FFF;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 0; }

      a {
        color: #000;
        text-decoration: none;
        font-style: italic; } } }

  &:before, &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #FFF;
    transition: all .5s ease-in-out;
    z-index: 12;
    transform: translate(-50%,-50%);
    opacity: 0; }

  &:before {
    height: 0;
    width: 2px; }

  &:after {
    width: 0;
    height: 2px; }

  &:hover {

    &:before {
      height: 40px;
      opacity: 1; }

    &:after {
      width: 40px;
      opacity: 1; }

    img {
      opacity: 0.3;
      -webkit-transform: scale3d(1,1,1);
      transform: scale3d(1,1,1); }

    .inner {
      &:before {
        -webkit-transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,100%,0);
        transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,100%,0); } }

    .portfolio-overlay {
      opacity: 1;
      transform: scale(1); } } }


.lg-backdrop {
  background-color: rgba(0,0,0,0.9); }

.width-1-4 {
  width: 19.65%; }

.width-1-2 {
  width: 39.35%; }


.gp-portfolio-item h2 {
  text-align: left; }


.gp-portfolio-item:hover {}

.gp-portfolio-item:hover .inner::before {
  -webkit-transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,100%,0);
  transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,100%,0); }


.gp-portfolio-gallery-two {
  .gp-portfolio-item {
    margin: 0; } }

