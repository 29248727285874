#about {
  padding: 100px 0 40px 0;
  background: #f5f5f5;

  &.about-us-two {
    padding: 100px 0;

    .about-content {
      p {
        margin-bottom: 30px; } } } }

.about-me {
  h2 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 26px;
    margin-bottom: 30px;
    position: relative;
    padding-bottom: 15px;

    &:after {
      position: absolute;
      content: '';
      left: 0;
      bottom: 0;
      width: 50px;
      height: 3px;
      background: $color_theme; } }

  .ab-content {
    margin-bottom: 50px;
    font-size: 16px; } }

.feature-item-one {
  margin-bottom: 50px;

  .feature-icon {
    float: left;
    height: 40px;
    width: 40px;
    color: $color_theme;

    i {
      font-size: 30px; } }

  .content {
    margin-left: 40px;

    h4 {
      font-weight: 700;
      font-size: 20px; }

    p {
      font-size: 14px; } } }


.ab-thumb {
  // height: 450px
  // width: 500px
  margin: 0 auto;
  position: relative;
  z-index: 999;
  box-shadow: 0 20px 40px rgba(0,0,0,0.3);

  &:before {
    position: absolute;
    content: '';
    height: 450px;
    width: 500px;
    border: 5px solid #dcdcdc;
    right: -20px;
    bottom: -20px;
    z-index: -2; }


  img {
    width: 100%;
    height: auto;
    z-index: 100; } }

.about-thumb {
  img {
    width: 100%; } }

/*=========  About Us  ==========*/
.caption {
  text-align: center;

  h2 {
    font-size: 40px;
    font-weight: 300;
    line-height: 50px;
    color: #333;
    margin-bottom: 50px; } }

.feature-item-three {
  text-align: center;
  margin-bottom: 50px;

  h3 {
    font-size: 26px;
    font-weight: 700;
    color: #333;
    margin-bottom: 20px; }

  .service-icon {
    margin-bottom: 20px;
    i {
      color: $color_theme;
      font-size: 50px; } } }
