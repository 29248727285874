#footer {
  background: #000;
  overflow: hidden;

  .footer-inner {
    padding: 60px 0;
    background: #131313; }

  .footer-item {
    width: 33%;
    float: left;

    .copyright {
      color: #FFF;
      font-size: 16px;
      padding: 12px 40px;

      p {
        margin-bottom: 0; } }

    .footer-logo {
      padding: 4px 0;
      a {
        display: inline-block; } } } }

.copyright {
  padding: 15px 0; }

.gp-social-link {
  margin: 0;
  padding: 10px 20px;
  list-style: none;

  li {
    display: inline-block;
    height: 30px;
    width: 30px;
    line-height: 30px;
    background: rgba(50,50,50,0.5);
    margin-right: 7px;
    transition: all 0.3s ease-in-out;
    text-align: center;

    a {
      color: #FFF; }

    &:hover {
      background: $color_theme; } } }

.fotter-about {
  p {
    color: #ccc; } }

.footer-logo {
  margin-bottom: 20px; }

.footer-title {
  color: $color_theme;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 25px;
  position: relative;
  padding-bottom: 10px;

  &:after {
    position: absolute;
    left: 0;
    bottom: 0;
    content: '';
    background: $color_theme;
    height: 3px;
    width: 40px; } }

.footer-gallery {
  .gallery-item {
    height: 80px;
    width: 80px;
    float: left;
    margin-right: 5px;
    margin-bottom: 5px;

    img {
      width: 100%;
      height: auto; } } }

.footer-form {
  p {
    color: #ccc;
    font-size: 16px; } }

.subscrib-form {

  input {
    width: 80%;
    background: transparent;
    color: #FFF;
    height: 45px;
    border: 1px solid #565656;
    padding: 10px;
    font-size: 16px;
    margin-bottom: 10px;
    outline: none; }

  .gp-btn {
    padding: 10px 40px;
    text-transform: uppercase; } }

