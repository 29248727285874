#team {
  padding: 100px 0;
  background: #181818;
  overflow: hidden; }

.team-member {
  position: relative;
  cursor: pointer;
  max-width: 400px;
  margin: 0 auto 20px;

  .member-img {
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      transform: scale(1.05);
      transition: all 0.3s ease-in-out; } }

  .profail {
    background: rgba(0,0,0,0.5);
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: all 0.3s ease-in-out;

    .content {
      position: absolute;
      width: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      text-align: center;
      padding: 20px 50px;

      p {
        color: #FFF;
        font-weight: 600;
        font-size:  16px;
        transition: all 0.3s ease-in-out;
        transform: translateY(20px);
        opacity: 0; } } }

  .team-profail {
    margin-bottom: 20px;
    padding: 0 0 20px 0;
    list-style: none;
    position: relative;

    &:after {
      position: absolute;
      left: 50%;
      bottom: 0;
      content: '';
      height: 3px;
      width: 0;
      transition: all 0.3s ease-in-out;
      background: $color_theme;
      transform: translateX(-50%); }

    li {
      height: 40px;
      width: 40px;
      background: #FFF;
      line-height: 40px;
      display: inline-block;
      text-align: center;
      margin: 0 2px;
      border-radius:  4px;
      transition: all 0.3s ease-in-out;
      a {
        color: #000;
        font-size: 20px; }

      &:hover {
        background: $color_theme;
        a {
          color: #FFF; } } } }

  &:hover {
    .member-img {
      img {
        transform: scale(1); } }
    .profail {
      opacity: 1; }

    .content {
      p {
        transform: translateY(0);
        opacity: 1; } }

    .team-profail {
      &:after {
        width: 250px; } } } }


.name {
  text-align: center;
  background: #FFF;
  padding: 15px 0;

  h3 {
    color: $color_theme;
    font-weight: 700;
    margin: 0;
    font-size: 24px; }

  p {
    margin-bottom: 0;
    font-size: 16px; } }
