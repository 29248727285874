// Font Smoothing
@mixin font-smoothing {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

// Font Face
// Based on http://codepen.io/javasteve99/pen/hDxpn
@mixin font-face($font-family, $file-path, $font-weight: normal, $font-style: normal) {
    @font-face {
      font-family: $font-family;
      src: url('#{$file-path}.eot');
      src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'), url('#{$file-path}.woff2') format('woff2'), url('#{$file-path}.woff') format('woff'), url('#{$file-path}.ttf') format('truetype'), url('#{$file-path}.svg##{$font-family}') format('svg');
      font-weight: $font-weight;
      font-style: $font-style; }

    // Chrome for Windows rendering fix: http://www.adtrak.co.uk/blog/font-face-chrome-rendering/
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
      @font-face {
        font-family: $font-family;
        src: url('#{$file-path}.svg##{$font-family}') format('svg'); } } }




// Clear Fix
@mixin clearfix() {
  &:before,
  &:after {
    content: "";
    display: table; }
  &:after {
    clear: both; } }

// Font Size
@mixin font-size($pixel: 14, $context: 14 ) {
  font-size: #{$pixel}px;
  font-size: #{$pixel/$context}em; }

// Letter Spacing
@mixin letter-spacing($value: 1) {
  letter-spacing: ($value / 1000 ) * 1em; }

// Line height
@mixin line-height($pixel: 14, $context: 14 ) {
  line-height: #{$pixel}px;
  line-height: #{$pixel/$context}em; }

@-webkit-keyframes toRightFromLeft {
  49% {
    -webkit-transform: translate(100%); }

  50% {
    opacity: 0;
    -webkit-transform: translate(-100%); }

  51% {
    opacity: 1; } }


@-moz-keyframes toRightFromLeft {
  49% {
    -moz-transform: translate(100%); }

  50% {
    opacity: 0;
    -moz-transform: translate(-100%); }

  51% {
    opacity: 1; } }


@keyframes toRightFromLeft {
  49% {
    transform: translate(100%); }

  50% {
    opacity: 0;
    transform: translate(-100%); }

  51% {
    opacity: 1; } }
