#my-skills {
	padding: 100px 0; }

.skills {
	margin-top: 50px; }

.skills-wrapper {
  max-width: 500px;
  margin: 0 auto; }

.skills-thumb {
	img {
		width: 100%; } }

.skills-content {
	p {
		color: #000;
		margin-bottom: 80px; } }
.progress {
	border-radius: 0;
	height: 15px;
	margin-bottom: 40px;
	overflow: visible;
	box-shadow: none; }

.skill-bar {
	text-align: left !important;
	margin: 0;
	padding-left: 0;
	list-style: none;

	li {}
	.skill-bar-wrap {
		margin-bottom: 20px;
		box-shadow: none; }

	.progress-bar {
		height: 15px;
		width: 0;
		display: block;
		text-align: right;
		overflow: visible !important;
		position: relative;
		box-shadow: none;
		border-radius: 0 !important;
		opacity: 1;
		background: $color_theme;

		&:after {
			position: absolute;
			top: -8px;
			right: 5px;
			content: '';
			width: 0;
			height: 0;
			border-left: 6px solid transparent;
			border-right: 6px solid transparent;
			border-bottom: 8px solid $color_theme; }


		.skill-name, .percentage {
			color: #000;
			position: relative;
			top: -25px; }

		.skill-name {
			position: absolute;
			left: 0;
			font-weight: 700;
			font-size:  14px; } } }

/*=========  Choose Two  ==========*/
#choose-us {
  background: #f0f0f0; }

.choose-item {
  padding: 50px 0 0; }

.feature-item-four {

  &:nth-child(1), &:nth-child(2) {
    margin-bottom: 40px; }

  .feature-icon {
    height: 55px;
    width: 55px;
    background: $color_theme;
    border-radius: 50%;
    text-align: center;
    margin-bottom: 10px;
    line-height: 61px;

    i {
      font-size: 25px;
      color: #FFF; } } }
