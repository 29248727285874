@media screen and (max-width: 1400px) {

  /*=========  Portfolio  ==========*/
  .gp-portfolio-item {
    .portfolio-overlay {
      .content {
        bottom: 5px;
        h4 {
          font-size: 15px; } } } } }

@media screen and (max-width: 1024px) {

  /*=========  Header Menu  ==========*/
  .menu--shylock {
    .menu__link {
      margin: 0 0.5em; } }

  .shere-menu {
    margin-left: 7px; }

  /*=========  Banner  ==========*/
  .static-banner-content {
    h4 {
      font-size: 24px; }

    h2 {
      font-size: 65px;
      line-height: 70px; } }

  /*=========  Portfolio  ==========*/
  .gp-portfolio-item {
    .portfolio-overlay {
      .content {
        bottom: 5px;
        h4 {
          font-size: 15px; } } } }

  /*=========  Choose Thumb  ==========*/
  .choose-thumb {
    width: 496px;
    margin-top: 210px;

    img {
      width: 100%; } }


  /*=========  Pricing  ==========*/
  .gp-pricing {
    .pricing-feature-list {
      padding: 2em; } } }

@media screen and (max-width: 768px) {

  /*=========  Base  ==========*/
  .section-title {
    h2 {
      font-size: 28px; } }

  .sub-title {
    br {
      display: none; } }

  /*=========  Header Menu  ==========*/

  #header {
    .header-wrapper {
      padding: 5px 0; }

    .top-menu {
      display: none; } }

  .menu-list {
    display: none; }

  #header {
    &.navbar-small {
      height: 50px;

      .header-wrapper {
        padding: 5px 0; } } }

  /*=========  Banner  ==========*/
  .banner {
    .author-details {
      h1 {
        font-size: 40px; }

      h2 {
        font-size: 20px; } }

    .static-banner-content {
      h4 {
        font-size: 20px; }

      h2 {
        font-size: 40px;
        line-height: 30px; }

      p {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 20px; }

      .gp-btn {
        padding: 10px 20px;
        font-size: 13px; } } }

  /*=========  About  ==========*/
  .ab-thumb {
    &:before {
      height: 370px;
      width: 390px; } }


  .caption {
    h2 {
      font-size: 24px;
      line-height: 30px; } }

  .feature-item-three {
    max-width: 400px;
    margin: 0 auto 40px; }

  .about-thumb {
    margin-top: 20px; }

  .my-photo {
    max-width: 400px;
    margin: 0 auto 20px;
    img {
      width: 100%; } }

  .profile-list {
    margin: 0 0 25px 0; }

  .profile-list {
    li {
      margin-bottom: 10px;

      .title {
        font-size: 15px; }

      .cont {
        font-size: 15px; } } }

  .dwn-btn {
    padding: 8px 0;
    width: 150px;
    font-size: 13px;
    margin-bottom: 20px; }

  /*=========  Exprience  ==========*/
  .bs-exprience {
    padding: 50px 0;
    h3 {
      margin-bottom: 20px; } }

  /*=========  Service  ==========*/
  #service {
    padding: 50px 0; }

  /*=========  Portfolio  ==========*/
  #portfolio {
    padding: 50px 0; }

  .width-1-4 {
    width: 46%; }

  .width-1-2 {
    width: 46%; }

  .gp-portfolio-item {
    margin: 4px 1%; }


  /*=========  Choose Two  ==========*/
  .feature-item-four {
    margin: 0 auto 30px;
    max-width: 400px; }

  .choose-thumb {
    max-width: 400px;
    margin: 0 auto;
    width: 100%;

    img {
      width: 100%; } }

  /*=========  Skills  ==========*/
  #my-skills {
    padding: 50px 0; }

  .skills {
    margin-top: 0; }

  .skills-thumb {
    max-width: 500px;
    margin: 0 auto 30px; }

  .skills-wrapper {
    margin: 20px 0 0; }

  .feature-item-one {
    width: 50%;
    border-bottom: 1px solid #e8eaea; }

  /*=========  CountUp  ==========*/
  .counter-box {
    margin-bottom: 30px; }

  /*=========  Pricing  ==========*/
  #pricing {
    padding: 50px 0; }

  /*=========  Team  ==========*/
  #team {
    padding: 50px 0; }

  /*=========  Blog  ==========*/
  #blog {
    padding: 50px 0; }


  /*=========  Contact  ==========*/
  #contact {
    padding: 50px 0;

    .gp-btn {
      margin-bottom: 20px; } }

  /*=========  Footer  ==========*/
  #footer {
    .footer-item {
      .copyright {
        padding: 12px 0 0 20px; } } }

  .gallery {
    margin-bottom: 15px; }

  .gp-social-link {
    padding: 10px 0; }

  .copyright {
    p {
      left: 18px; } } }


@media screen and (max-width: 500px) {

  /*=========  Banner  ==========*/
  .banner {
    height: 70vh;
    .author-details {
      width: 100%;

      h1 {
        font-size: 45px; } } }

  .banner {
    .static-banner-content {
      h4 {
        font-size: 16px; }

      h2 {
        font-size: 30px;
        line-height: 20px; }

      p {
        font-size: 12px; }

      .gp-btn {
        padding: 8px 15px; } } }

  /*=========  About  ==========*/
  .ab-thumb {
    &:before {
      height: 250px;
      width: 300px; } }

  /*=========  Portfolio ==========*/
  .portfolio-filter, .portfolio-filter-two {
    padding-left: 0;

    li {
      margin-right: 0;

      a {
        padding: 10px;
        font-size: 10px; } } }


  .width-1-4 {
    width: 100%; }

  .width-1-2 {
    width: 100%; }

  /*=========  Testimonial  ==========*/
  #testimonial {
    padding: 50px 0; }


  .testi-content {
    p {
      width: 100%; } }

  .tm-control {
    display: none; }


  /*=========  Footer  ==========*/
  #footer {
    .footer-item {
      width: 100%;
      float: none;
      text-align: center; } } }
