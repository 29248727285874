/* Banner */
.banner {
  height: 100vh;
  background-size: cover;
  position: relative;

  .author-details {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    h5 {
      color: #000;
      font-size: 18px;
      font-weight: 300; }

    h1 {
      color: #000;
      font-size: 70px;
      text-transform: uppercase;
      font-weight: 800;
      letter-spacing: 2px; }


    h2 {
      text-align: center;

      color: #000;
      font-weight: 700;

      span {
        color: $color_theme;
        font-weight: 700; } } } }


/* Static Banner */
.static-banner-content {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  transform: translate(-50%,-50%);

  h4 {
    font-size: 30px;
    color: #FFF;
    margin-top: 0; }

  h2 {
    font-size: 80px;
    line-height: 90px;
    color: #FFF;
    font-weight: 800;
    font-family: 'Raleway', sans-serif;

    span {
      color: $color_theme; } }

  p {
    color: #fff;
    font-size: 18px;
    font-weight: 300;
    line-height: 26px;
    margin-bottom: 40px; }

  .gp-btn {
    padding: 14px 40px;
    font-size: 16px;
    text-transform: uppercase;
    background: transparent;
    border: 1px solid #FFF;

    &:hover {
      border-color: transparent; } }

  .btn-active {
    margin-left: 20px;
    background: $color_theme;
    border-color: transparent;

    &:hover {
      color: #000;
      border-color: transparent;

      &:before {
        background: #FFF; } } } }
