#header {
  transition: all 0.5s ease-in-out;
  z-index: 600;
  position: absolute;
  top: 0;
  width: 100%;
  border-bottom: 1px solid #333;

  &.header-two {
    background: rgba(0,0,0,0.6);

    &.navbar-small {
      .search-wrapper {
        top: 0;
        height: 63px;
        background: #f5f5f5;

        input {
          height: 40px;
          margin: 11px auto;
          border-color:  rgba(80, 80, 80, 0.3); }

        .close-btn {
          color: #000;
          top: 17px; } } } }

  .header-wrapper {
    padding: 15px 0;
    transition: all 0.1s ease-in-out; }

  .shere-menu {
    padding: 7px 0; }

  .site-logo {
    display: inline-block;
    float: left;
    margin: 4px 0;

    .logo-dark {
      display: none; } }

  &.navbar-small {
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    box-shadow: 0 10px 20px rgba(50,50,50,0.1);
    transition: all 0.3s ease-in-out;
    border-color: transparent;

    .top-menu {
      display: none; }


    .header-wrapper {
      padding: 10px 0; }

    .menu-list {
      .menu-item {
        .menu-link {
          color: #000; } } }

    .shere-menu {
      padding: 6px 0; }

    .site-logo {
      margin: 0;

      .logo-light {
        display: none; }

      .logo-dark {
        display: block; } } } }


.menu {
  float: right;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex; }

.shere-menu {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  margin-left: 30px; }

.share {
  a {
    color: $color-theme; }
  i {
    font-size: 20px;
    line-height: 30px;
    margin-right: 10px; } }

.search-btn {
  a {
    font-size: 16px;
    line-height: 28px;
    margin-right: 10px;
    color: $color_theme; } }

.menu-toggle {
  position: relative;
  width: 30px;
  height: 16px;
  float: right;
  margin: 6px;

  &:hover {
    cursor: pointer; }

  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    height: 1px;
    background-color: $color-theme;
    transition: all 0.5s ease-in-out;

    &:nth-child(2) {
      top: 50%;
      margin-top: -1px; }

    &:nth-child(3) {
      top: auto;
      bottom: 0; } }

  &.active {
    > div {
      &:nth-child(1) {
        transform: translateY(10px) rotate(135deg); }

      &:nth-child(2) {
        opacity: 0; }

      &:nth-child(3) {
        transform: translateY(-12px) rotate(-135deg); } } } }

.home-static {
  #header {
    background: rgba(0,0,0,0.5);

    &.navbar-small {
      background: #fff; } } }



.menu {
  line-height: 1; }

.menu-list {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none; }

.menu-item {
  display: block;

  .child-menu {
    left: 0px;
    position: absolute;
    top: 135%;
    width: 200px;
    visibility: hidden;
    z-index: 999;
    opacity: 0;
    transform: translateY(-20px);
    background: none 0px 0px repeat scroll #000;
    list-style: none outside none;
    margin: 0px;
    padding: 0px;
    transition: all 800ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition-timing-function: cubic-bezier(0.680, -0.550, 0.265, 1.550);
    text-align: left;
    border-top: 2px solid $color_theme;

    li {
      padding: 0;
      border-bottom: 1px solid #4e4e4e;
      transition: all 0.3s ease-in-out;

      a {
        display: block;
        padding: 0;
        color: #FFF !important;
        font-size: 14px;
        padding: 14px;

        &:after {
          display: none; } }

      &:last-child {
        border-bottom: none; }

      &:hover {
        background-color: $color_theme;
        a {
          color: #FFF !important;
          display: block;
          text-decoration: none !important;
          padding-left: 20px; } } }

    &:hover, &:focus {
      color: $color_theme; } }

  &:hover {
    .child-menu {
      transform: translateY(0);
      visibility: visible;
      transition: all 0.5s ease-out;
      opacity: 1;
      z-index: 9999; } } }

.menu-link {
  font-size: 13px;
  font-weight: 500;
  display: block;
  padding: 1em;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: $font_header;
  text-transform: uppercase;
  letter-spacing: 1px; }

.menu-link:hover,
.menu-link:focus {
  outline: none;
  text-decoration: none; }


.menu--shylock .menu-link {
  position: relative;
  margin: 0 1em;
  padding-right: 0;
  padding-left: 0;
  color: #FFF;
  -webkit-transition: color 0.4s;
  transition: color 0.4s; }


.menu--shylock .menu-item-current .menu-link,
.menu--shylock .menu-item-current .menu-link:hover,
.menu--shylock .menu-item-current .menu-link:focus {
  color: $color_theme; }


.menu--shylock .menu-item-current .menu-link::after,
.menu--shylock .menu-item-current .menu-link::before {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1); }


.menu--shylock .menu-item-current .menu-link::before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s; }


.menu--shylock .menu-link:hover,
.menu--shylock .menu-link:focus {
  color: $color_theme; }


.menu--shylock .menu-link:hover::before,
.menu--shylock .menu-link:focus::before {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition-delay: 0s;
  transition-delay: 0s; }


.menu--shylock .menu-link::before,
.menu--shylock .menu-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  -webkit-transform: scale3d(0, 1, 1);
  transform: scale3d(0, 1, 1);
  -webkit-transform-origin: center left;
  transform-origin: center left;
  -webkit-transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
  transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1); }


.menu--shylock .menu-link::before {
  background: #FFF;
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s; }


.menu--shylock .menu-link::after {
  background: $color_theme; }

.top-menu {
  background: #333;

  .info {
    padding: 5px 0;

    p {
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 0;
      color: #FFF;
      position: relative;

      &:first-child {
        &:after {
          position: absolute;
          content: '';
          height: 14px;
          width: 2px;
          background: #777777;
          right: -13px;
          top: 6px; } }

      i {
        color: $color_theme;
        margin-right: 5px; } } } }

.top-link {
  .gp-social-link {
    padding: 5px 0;
    li {
      background: transparent;
      height: auto;
      width: auto;
      line-height: 14px;
      margin-right: 10px;

      a {
        color: #fff; }

      &:hover {
        background-color: transparent;

        a {
          color: $color_theme; } } } } }


/*=========  Mobile Menu  ==========*/

#SideNav {
  position: fixed;
  width: 300px;
  height: 100vh;
  z-index: 9999;
  background: #131313;
  right: -300px;
  transition: right 0.3s;
  padding: 80px 15px 20px;
  overflow-y: scroll;

  &.SideNavOpen {
    right: 0; }

  .CloseNav {
    font-size: 22px;
    font-weight: 300;
    color: #FFF;
    position: absolute;
    right: 20px;
    top: 20px;
    display: block;
    cursor: pointer; } }

#accordian {
  margin-bottom: 70px; }

.accordion-menu {
  margin: 0;
  padding-left: 0;
  list-style: none;

  li {
    a {
      color: #adadad;
      text-decoration: none;
      padding: 10px;
      font-size: 16px;
      font-weight: 600;
      border-bottom: 1px solid #2b2b2b;
      display: block;
      text-transform: uppercase;


      &:hover {
        background: $color_theme;
        color: #FFF; } }

    &.open {
      .dropdownlink {
        color: $color_theme; }

      .fa-chevron-down {
        transform: rotate(180deg); } } } }

.dropdownlink {
  cursor: pointer;
  display: block;
  padding: 5px 10px;
  font-size: 18px;
  color: #212121;
  position: relative;
  transition: all 0.4s ease-out;

  i {
    position: absolute;
    top: 13px;
    left: 16px;
    font-size: 14px; }

  .fa-chevron-down {
    right: 12px;
    left: auto; } }


.submenuItems {
  margin: 0;
  padding-left: 0;
  list-style: none;
  display: none;
  background: #181818;
  li {
    border-bottom: 1px solid #252525;

    a {
      color: #adadad;
      font-size: 13px;
      padding-left: 15px; }

    &:last-child {
      border-bottom: none; } } }

.submenuItems a {
  display: block;
  color: #FFF;
  padding: 12px;
  transition: all 0.4s ease-out;
  text-decoration: none;

  &:hover, &:focus {
    background-color: $color_theme;
    color: #fff; } }

.mob-search-form {
  input {
    width: 75%;
    background: transparent;
    border: 1px solid #444;
    padding: 6px;
    color: #FFF;
    outline: none;

    &:focus {
      outline: none; } }

  button {
    border: none;
    background: #00b9a1;
    color: #FFF;
    padding: 7px 18px; } }

#SideNav {
  .gp-social-link {

    li {
      margin-right: 2px;
      height: 37px;
      width: 37px;
      line-height: 37px;

      a {
        display: block; } } } }

.search-wrapper {
    position: absolute;
    right: 24%;
    width: 56%;
    background: #FFF;
    top: 36px;
    height: 73px;
    background: #000;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    z-index: -1;

    input {
        width: 95%;
        padding: 10px 50px 10px 10px;
        height: 50px;
        font-size: 20px;
        margin: 12px auto;
        display: block;
        background-color: transparent;
        color: #FFF;
        border: 0;
        border: 1px solid rgba(255,255,255,0.3);
        outline: none; }

    .close-btn {
        position: absolute;
        top: 23px;
        right: 40px;
        color: #FFF;
        font-size: 20px; }

    &.search-open {
        opacity: 1;
        cursor: pointer;
        z-index: 1; } }
