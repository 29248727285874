#pricing {
  padding: 100px 0;
  background: #f8f8f8; }

.pricing-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
  flex: 0 1 330px;
  max-width: 400px;
  margin: 0 auto 20px; }



.pricing-feature-list {
  text-align: left; }

.pricing-action:focus {
  outline: none; }



.gp-pricing {
  .pricing-item {
    padding: 0 0 2em;
    color: #fff;
    box-shadow: 0 5px 20px rgba(0,0,0,0.05), 0 15px 30px -10px rgba(0,0,0,0.3);
    transition: background 0.3s;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    .pricing__sentence {
      margin-bottom: 4em;
      color: #f5f5f5; }

    .pricing-price {
      font-size: 2em;
      font-weight: bold;
      position: relative;
      z-index: 10;
      overflow: hidden;
      padding: 0.75em;
      cursor: default;
      color: $color_theme;
      background: #1a181b;
      -webkit-transition: color 0.3s;
      transition: color 0.3s; }

    &.pricing-dark {
      background: #1F1F1F;

      .pricing-title {
        color: #FFF; }

      &:hover {
        background: #141315; } }

    &.pricing-light {
      background-color: #FFF;
      box-shadow: 0 5px 20px rgba(0,0,0,0.03), 0 15px 30px -10px rgba(0,0,0,0.1);

      .pricing-title {
        color: #333; }

      .pricing__sentence {
        color: #444; }

      .pricing-feature-list {
        li {
          i {
            color: #333;
            font-size: 15px; } } }

      .pricing-action {
        background: transparent;
        color: #000;
        border: 1px solid #000;
        padding: 1em;
        margin: 0 5em;
        border-radius: 0;
        font-size: 14px;

        &:hover {
          background: $color_theme;
          color: #FFF;
          border-color: $color_theme; } } }

    .pricing-title {
      font-size: 2.35em;
      font-weight: 900;
      line-height: 1;
      width: 290px;
      margin: 0 auto;
      padding: 1em 1em 0em;
      text-transform: uppercase; }


    .pricing__amp {
      padding: 0.15em 0 0.1em;
      color: #0f0e0f; }

    &:hover {
      .pricing-price {
        color: #fff; } } } }


.gp-pricing .pricing-price::before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: $color_theme;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  -webkit-transform: translate3d(-150%,0,0) skewX(40deg);
  transform: translate3d(-150%,0,0) skewX(40deg); }


.gp-pricing .pricing-item:hover .pricing-price::before {
  opacity: 1;
  -webkit-transform: translate3d(0,0,0) skewX(0deg);
  transform: translate3d(0,0,0) skewX(0deg); }


.gp-pricing .pricing__period {
  font-size: 0.5em;
  font-weight: normal;
  display: block;
  color: #848484; }


.gp-pricing .pricing-feature-list {
  margin: 0;
  padding: 2em 5em;
  list-style: none;
  color: #6a6563;

  li {
    font-size: 16px;
    font-weight: 700;
    i {
      color: #FFF;
      margin-right: 10px; } } }

.pricing-action {
  color: inherit;
  border: none;
  background: none;
  font-weight: bold;
  margin: 0 2em;
  padding: 1em 2em;
  border-radius: 4px;
  background: $color_theme;
  transition: background-color 0.3s, color 0.3s;

  &:hover, &:focus {
    color: #ef7d46;
    background: #fff; } }
