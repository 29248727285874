#page-banner {
  padding: 150px;
  background-size: cover;
  text-align: center; }

.page-heading {
  background: rgba(0,0,0,0.5);
  padding: 20px;
  display: inline-block;

  h1 {
    color: #FFF;
    text-transform: uppercase;
    font-size: 50px;
    font-weight: 700; }

  .page-link {
    a {
      display: inline-block;
      color: #FFF;
      font-weight: 300;
      margin-right: 20px;
      position: relative;

      &:after {
        position: absolute;
        content: '/';
        right: -15px;
        top: 1px; }

      &:last-child {
        &:after {
          display: none; } }

      &:hover {
        color: $color_theme;
        text-decoration: none; } } } }
