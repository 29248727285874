.widget_dt_search {

  .search-form {
    width: 100%;

    label {
      position: relative;
      width: 270px; }


    .search-icon {
      position: absolute;
      right: 6px;
      top: 12px; } }

  input[type="search"] {
    width: 100%;
    border: 1px solid #eeeeee;
    outline: none;
    padding: 10px 20px;
    color: #111;
    @include font-size(14); }

  .search-submit {
    display: none; } }


/*=========  Author  ==========*/
#author {
  background: $color_theme;
  padding: 20px 15px;
  margin-bottom: 30px;
  img {
    height: 115px;
    width: 115px;
    margin-bottom: 20px; }

  .my-details {
    h4 {
      color: #000;
      font-size: 20px;
      font-weight: 700; }

    h5 {
      color: #f5f5f5; }

    p {
      margin-bottom: 0;
      color: #191919; } } }
