/*!
  Theme Name: Engage
  Theme URI: http://www.decentthemes.com/products/corpo-wordpress/
  Author: Golden Plus Theme
  Author URI: http://www.mominul.me/
  Description: The Ultimate Financial / Business Template
  Version: 1.0.0
  License: GNU General Public License v2 or later
  License URI: http://www.gnu.org/licenses/gpl-2.0.html
  Text Domain: engage
  Tags:
  */
/*--------------------------------------------------------------
  >>> TABLE OF CONTENTS:
  ----------------------------------------------------------------
  # Base rules
  # Library
    ## Example
  # Elements
    ## Accessibility
    ## Buttons
  # Navigation Menu
    # Top Menu
    # Primary Menu
  # Sections
    ## Loading
    ## Header

  # Widgets
    ## Search
    ## Dt Custom Menu
    ## Dt Download Menu
    ## Dt Tags
    ## Dt Resent Post
    ## Dt Archive
  --------------------------------------------------------------*/
@-webkit-keyframes toRightFromLeft {
  49% {
    -webkit-transform: translate(100%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translate(-100%);
  }
  51% {
    opacity: 1;
  }
}

@-moz-keyframes toRightFromLeft {
  49% {
    -moz-transform: translate(100%);
  }
  50% {
    opacity: 0;
    -moz-transform: translate(-100%);
  }
  51% {
    opacity: 1;
  }
}

@keyframes toRightFromLeft {
  49% {
    transform: translate(100%);
  }
  50% {
    opacity: 0;
    transform: translate(-100%);
  }
  51% {
    opacity: 1;
  }
}

/*--------------------------------------------------------------
  # Base rules
  --------------------------------------------------------------*/
html {
  font-size: 14px;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #6d6c6c;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  overflow: hidden;
  overflow-y: scroll;
  line-height: 26px;
  color: #111;
}

p {
  color: #6d6c6c;
  font-size: 14px;
  line-height: 24px;
  font-family: "Lato", sans-serif;
}

a {
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}

.margin-right {
  margin-right: 20px;
}

.section-padding {
  padding: 0 20px 0 15px;
}

.burgar_menu .margin-left {
  margin-left: 0;
}

.burgar_menu .section-padding {
  padding-left: 0;
}

.burgar_menu .menu_slide {
  background-color: transparent;
  height: 70px;
}

.section-title h5 {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  font-weight: 300;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.section-title h2 {
  text-transform: uppercase;
  color: #000;
  margin-top: 0;
  position: relative;
  margin-bottom: 15px;
  position: relative;
  display: inline-block;
  font-size: 36px;
  padding-bottom: 15px;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-bottom: 30px;
  font-weight: 900;
  margin-bottom: 30px;
}

.section-title h2:after {
  position: absolute;
  content: ' ';
  background: #00baa2;
  width: 70px;
  height: 3px;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}

.section-title.text-left h2:after {
  left: 0;
  transform: translateX(0);
}

.sub-title {
  color: #000;
  margin-bottom: 50px;
  line-height: 24px;
  font-size: 18px;
  font-style: italic;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(53, 152, 219, 0.8);
}

.color-black {
  color: #000;
}

.section-dark .section-title h2 {
  color: #FFF;
}

.section-dark .section-title h5 {
  color: #a9a9a9;
}

.section-dark .section-title p {
  color: #d2d2d2;
}

h1, h2, h3, h4, h5 {
  color: #000;
  margin-top: 0;
  font-family: "Raleway", sans-serif;
}

/* Back To Top */
.return-to-top {
  position: fixed;
  bottom: 80px;
  right: 0px;
  background: #00baa2;
  width: 42px;
  height: 42px;
  text-decoration: none;
  opacity: 0;
  transition: all 0.3s ease;
  z-index: 1000;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.return-to-top i {
  color: #fff;
  margin: 0;
  position: relative;
  left: 11px;
  top: 9px;
  font-size: 19px;
  transition: all 0.3s ease;
  margin-left: 3px;
}

.return-to-top:hover {
  background: rgba(0, 0, 0, 0.9);
}

.return-to-top:hover i {
  color: #fff;
  top: 5px;
}

.back-top {
  opacity: 1;
}

/*=========  Page Loader  ==========*/
.loading-block {
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: black;
}

.page-loaded .loading-block {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.loading-block .loading-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
}

/**
 *
 * All animations must live in their own file
 * in the animations directory and be included
 * here.
 *
 */
@-webkit-keyframes ball-scale-multiple {
  0% {
    -webkit-transform: scale(0);
    -webkit-transform-transform: scale(0);
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    -webkit-transform-transform: scale(1);
    opacity: 0;
  }
}

@keyframes ball-scale-multiple {
  0% {
    -webkit-transform: scale(0);
    -webkit-transform-transform: scale(0);
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    -webkit-transform-transform: scale(1);
    opacity: 0;
  }
}

.ball-scale-multiple {
  position: relative;
  -webkit-transform: translateY(-30px);
  -webkit-transform--ms-transform: translateY(-30px);
  -webkit-transform--ms-transform-transform: translateY(-30px);
}

.ball-scale-multiple > div:nth-child(2) {
  -webkit-animation-delay: -0.4s;
  -webkit-animation-delay-animation-delay: -0.4s;
}

.ball-scale-multiple > div:nth-child(3) {
  -webkit-animation-delay: -0.2s;
  -webkit-animation-delay-animation-delay: -0.2s;
}

.ball-scale-multiple > div {
  background-color: #b4b4b4;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin: 2px;
  -webkit-animation-fill-mode: both;
  -webkit-animation-fill-mode-animation-fill-mode: both;
  position: absolute;
  left: -30px;
  top: 0px;
  opacity: 0;
  margin: 0;
  width: 60px;
  height: 60px;
  -webkit-animation: ball-scale-multiple 1s 0s linear infinite;
  -webkit-animation-animation: ball-scale-multiple 1s 0s linear infinite;
}

.Switcher {
  background: #00baa2;
  text-align: center;
}

.Switcher:before {
  display: none;
}

.Switcher h5 {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #FFF;
  margin-bottom: 20px;
}

#Switcher__control {
  background-image: none;
  background: #00baa2;
}

#Switcher__control i {
  font-size: 20px;
  color: #FFF;
}

#colors {
  text-align: center;
  padding-left: 0;
}

#colors li {
  width: 70px;
  height: 40px;
  text-align: center;
  line-height: 37px;
  color: #FFF;
  border-radius: 30px;
  margin-bottom: 10px;
}

#colors li.light {
  background: #fff;
  color: #000;
}

/*--------------------------------------------------------------
  # Elements
  --------------------------------------------------------------*/
/*--------------------------------------------------------------
  ## Accessibility
  --------------------------------------------------------------*/
.says,
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}

/*--------------------------------------------------------------
  ## Buttons
  --------------------------------------------------------------*/
a:focus {
  text-decoration: none;
  color: #FFF;
}

.gp-bn-btn {
  position: relative;
  background-color: #00baa2;
  padding: 12px 25px;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  color: #FFF;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  letter-spacing: 1px;
}

.gp-bn-btn:hover {
  background: #111;
  color: #FFF;
  text-decoration: none;
}

.gp-bn-active {
  background-color: transparent;
  border: 1px solid #fff;
  margin-right: 0;
}

.gp-bn-active:hover {
  border-color: transparent;
}

.gp-btn {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
  overflow: hidden;
  background: #e1e1e1;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  padding: 10px 20px;
  cursor: pointer;
  background: #000;
  color: #FFF;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  border: none;
}

.gp-btn:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #00baa2;
  transform: scaleY(0);
  transform-origin: 50% 0;
  transition-property: transform;
  transition-duration: 0.4s;
  transition-timing-function: ease-out;
}

.gp-btn:hover, .gp-btn:focus {
  color: #FFF;
  text-decoration: none;
}

.gp-btn:hover:before, .gp-btn:focus:before {
  transform: scaleY(1);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.gp-btn:hover:after, .gp-btn:focus:after {
  width: 100%;
}

.gp-btn-bg {
  background: #111;
  color: #FFF;
}

.gp-btn-bg:after {
  background: #00baa2;
}

.gp-btn-bg:hover, .gp-btn-bg:focus {
  background: transparent;
  color: #FFF;
  text-decoration: none;
}

.gp-btn-bg:hover:after, .gp-btn-bg:focus:after {
  width: 100%;
}

.gp-btn-radius {
  border-radius: 5px;
}

.gp-bn-btn-2 {
  background-color: transparent;
  color: #FFF;
  border: 2px solid #FFF;
}

/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
/*--------------------------------------------------------------
  ## Primary Menu
  --------------------------------------------------------------*/
/*--------------------------------------------------------------
  # Sections
  --------------------------------------------------------------*/
/*--------------------------------------------------------------
  ##  Header
  --------------------------------------------------------------*/
#header {
  transition: all 0.5s ease-in-out;
  z-index: 600;
  position: absolute;
  top: 0;
  width: 100%;
  border-bottom: 1px solid #333;
}

#header.header-two {
  background: rgba(0, 0, 0, 0.6);
}

#header.header-two.navbar-small .search-wrapper {
  top: 0;
  height: 63px;
  background: #f5f5f5;
}

#header.header-two.navbar-small .search-wrapper input {
  height: 40px;
  margin: 11px auto;
  border-color: rgba(80, 80, 80, 0.3);
}

#header.header-two.navbar-small .search-wrapper .close-btn {
  color: #000;
  top: 17px;
}

#header .header-wrapper {
  padding: 15px 0;
  transition: all 0.1s ease-in-out;
}

#header .shere-menu {
  padding: 7px 0;
}

#header .site-logo {
  display: inline-block;
  float: left;
  margin: 4px 0;
}

#header .site-logo .logo-dark {
  display: none;
}

#header.navbar-small {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  box-shadow: 0 10px 20px rgba(50, 50, 50, 0.1);
  transition: all 0.3s ease-in-out;
  border-color: transparent;
}

#header.navbar-small .top-menu {
  display: none;
}

#header.navbar-small .header-wrapper {
  padding: 10px 0;
}

#header.navbar-small .menu-list .menu-item .menu-link {
  color: #000;
}

#header.navbar-small .shere-menu {
  padding: 6px 0;
}

#header.navbar-small .site-logo {
  margin: 0;
}

#header.navbar-small .site-logo .logo-light {
  display: none;
}

#header.navbar-small .site-logo .logo-dark {
  display: block;
}

.menu {
  float: right;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}

.shere-menu {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  margin-left: 30px;
}

.share a {
  color: #00baa2;
}

.share i {
  font-size: 20px;
  line-height: 30px;
  margin-right: 10px;
}

.search-btn a {
  font-size: 16px;
  line-height: 28px;
  margin-right: 10px;
  color: #00baa2;
}

.menu-toggle {
  position: relative;
  width: 30px;
  height: 16px;
  float: right;
  margin: 6px;
}

.menu-toggle:hover {
  cursor: pointer;
}

.menu-toggle > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: 1px;
  background-color: #00baa2;
  transition: all 0.5s ease-in-out;
}

.menu-toggle > div:nth-child(2) {
  top: 50%;
  margin-top: -1px;
}

.menu-toggle > div:nth-child(3) {
  top: auto;
  bottom: 0;
}

.menu-toggle.active > div:nth-child(1) {
  transform: translateY(10px) rotate(135deg);
}

.menu-toggle.active > div:nth-child(2) {
  opacity: 0;
}

.menu-toggle.active > div:nth-child(3) {
  transform: translateY(-12px) rotate(-135deg);
}

.home-static #header {
  background: rgba(0, 0, 0, 0.5);
}

.home-static #header.navbar-small {
  background: #fff;
}

.menu {
  line-height: 1;
}

.menu-list {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}

.menu-item {
  display: block;
}

.menu-item .child-menu {
  left: 0px;
  position: absolute;
  top: 135%;
  width: 200px;
  visibility: hidden;
  z-index: 999;
  opacity: 0;
  transform: translateY(-20px);
  background: none 0px 0px repeat scroll #000;
  list-style: none outside none;
  margin: 0px;
  padding: 0px;
  transition: all 800ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  text-align: left;
  border-top: 2px solid #00baa2;
}

.menu-item .child-menu li {
  padding: 0;
  border-bottom: 1px solid #4e4e4e;
  transition: all 0.3s ease-in-out;
}

.menu-item .child-menu li a {
  display: block;
  padding: 0;
  color: #FFF !important;
  font-size: 14px;
  padding: 14px;
}

.menu-item .child-menu li a:after {
  display: none;
}

.menu-item .child-menu li:last-child {
  border-bottom: none;
}

.menu-item .child-menu li:hover {
  background-color: #00baa2;
}

.menu-item .child-menu li:hover a {
  color: #FFF !important;
  display: block;
  text-decoration: none !important;
  padding-left: 20px;
}

.menu-item .child-menu:hover, .menu-item .child-menu:focus {
  color: #00baa2;
}

.menu-item:hover .child-menu {
  transform: translateY(0);
  visibility: visible;
  transition: all 0.5s ease-out;
  opacity: 1;
  z-index: 9999;
}

.menu-link {
  font-size: 13px;
  font-weight: 500;
  display: block;
  padding: 1em;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.menu-link:hover,
.menu-link:focus {
  outline: none;
  text-decoration: none;
}

.menu--shylock .menu-link {
  position: relative;
  margin: 0 1em;
  padding-right: 0;
  padding-left: 0;
  color: #FFF;
  -webkit-transition: color 0.4s;
  transition: color 0.4s;
}

.menu--shylock .menu-item-current .menu-link,
.menu--shylock .menu-item-current .menu-link:hover,
.menu--shylock .menu-item-current .menu-link:focus {
  color: #00baa2;
}

.menu--shylock .menu-item-current .menu-link::after,
.menu--shylock .menu-item-current .menu-link::before {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

.menu--shylock .menu-item-current .menu-link::before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.menu--shylock .menu-link:hover,
.menu--shylock .menu-link:focus {
  color: #00baa2;
}

.menu--shylock .menu-link:hover::before,
.menu--shylock .menu-link:focus::before {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.menu--shylock .menu-link::before,
.menu--shylock .menu-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  -webkit-transform: scale3d(0, 1, 1);
  transform: scale3d(0, 1, 1);
  -webkit-transform-origin: center left;
  transform-origin: center left;
  -webkit-transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
  transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.menu--shylock .menu-link::before {
  background: #FFF;
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.menu--shylock .menu-link::after {
  background: #00baa2;
}

.top-menu {
  background: #333;
}

.top-menu .info {
  padding: 5px 0;
}

.top-menu .info p {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 0;
  color: #FFF;
  position: relative;
}

.top-menu .info p:first-child:after {
  position: absolute;
  content: '';
  height: 14px;
  width: 2px;
  background: #777777;
  right: -13px;
  top: 6px;
}

.top-menu .info p i {
  color: #00baa2;
  margin-right: 5px;
}

.top-link .gp-social-link {
  padding: 5px 0;
}

.top-link .gp-social-link li {
  background: transparent;
  height: auto;
  width: auto;
  line-height: 14px;
  margin-right: 10px;
}

.top-link .gp-social-link li a {
  color: #fff;
}

.top-link .gp-social-link li:hover {
  background-color: transparent;
}

.top-link .gp-social-link li:hover a {
  color: #00baa2;
}

/*=========  Mobile Menu  ==========*/
#SideNav {
  position: fixed;
  width: 300px;
  height: 100vh;
  z-index: 9999;
  background: #131313;
  right: -300px;
  transition: right 0.3s;
  padding: 80px 15px 20px;
  overflow-y: scroll;
}

#SideNav.SideNavOpen {
  right: 0;
}

#SideNav .CloseNav {
  font-size: 22px;
  font-weight: 300;
  color: #FFF;
  position: absolute;
  right: 20px;
  top: 20px;
  display: block;
  cursor: pointer;
}

#accordian {
  margin-bottom: 70px;
}

.accordion-menu {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.accordion-menu li a {
  color: #adadad;
  text-decoration: none;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #2b2b2b;
  display: block;
  text-transform: uppercase;
}

.accordion-menu li a:hover {
  background: #00baa2;
  color: #FFF;
}

.accordion-menu li.open .dropdownlink {
  color: #00baa2;
}

.accordion-menu li.open .fa-chevron-down {
  transform: rotate(180deg);
}

.dropdownlink {
  cursor: pointer;
  display: block;
  padding: 5px 10px;
  font-size: 18px;
  color: #212121;
  position: relative;
  transition: all 0.4s ease-out;
}

.dropdownlink i {
  position: absolute;
  top: 13px;
  left: 16px;
  font-size: 14px;
}

.dropdownlink .fa-chevron-down {
  right: 12px;
  left: auto;
}

.submenuItems {
  margin: 0;
  padding-left: 0;
  list-style: none;
  display: none;
  background: #181818;
}

.submenuItems li {
  border-bottom: 1px solid #252525;
}

.submenuItems li a {
  color: #adadad;
  font-size: 13px;
  padding-left: 15px;
}

.submenuItems li:last-child {
  border-bottom: none;
}

.submenuItems a {
  display: block;
  color: #FFF;
  padding: 12px;
  transition: all 0.4s ease-out;
  text-decoration: none;
}

.submenuItems a:hover, .submenuItems a:focus {
  background-color: #00baa2;
  color: #fff;
}

.mob-search-form input {
  width: 75%;
  background: transparent;
  border: 1px solid #444;
  padding: 6px;
  color: #FFF;
  outline: none;
}

.mob-search-form input:focus {
  outline: none;
}

.mob-search-form button {
  border: none;
  background: #00b9a1;
  color: #FFF;
  padding: 7px 18px;
}

#SideNav .gp-social-link li {
  margin-right: 2px;
  height: 37px;
  width: 37px;
  line-height: 37px;
}

#SideNav .gp-social-link li a {
  display: block;
}

.search-wrapper {
  position: absolute;
  right: 24%;
  width: 56%;
  background: #FFF;
  top: 36px;
  height: 73px;
  background: #000;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: -1;
}

.search-wrapper input {
  width: 95%;
  padding: 10px 50px 10px 10px;
  height: 50px;
  font-size: 20px;
  margin: 12px auto;
  display: block;
  background-color: transparent;
  color: #FFF;
  border: 0;
  border: 1px solid rgba(255, 255, 255, 0.3);
  outline: none;
}

.search-wrapper .close-btn {
  position: absolute;
  top: 23px;
  right: 40px;
  color: #FFF;
  font-size: 20px;
}

.search-wrapper.search-open {
  opacity: 1;
  cursor: pointer;
  z-index: 1;
}

/*--------------------------------------------------------------
  ##  Header
  --------------------------------------------------------------*/
/* Banner */
.banner {
  height: 100vh;
  background-size: cover;
  position: relative;
}

.banner .author-details {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.banner .author-details h5 {
  color: #000;
  font-size: 18px;
  font-weight: 300;
}

.banner .author-details h1 {
  color: #000;
  font-size: 70px;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 2px;
}

.banner .author-details h2 {
  text-align: center;
  color: #000;
  font-weight: 700;
}

.banner .author-details h2 span {
  color: #00baa2;
  font-weight: 700;
}

/* Static Banner */
.static-banner-content {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

.static-banner-content h4 {
  font-size: 30px;
  color: #FFF;
  margin-top: 0;
}

.static-banner-content h2 {
  font-size: 80px;
  line-height: 90px;
  color: #FFF;
  font-weight: 800;
  font-family: 'Raleway', sans-serif;
}

.static-banner-content h2 span {
  color: #00baa2;
}

.static-banner-content p {
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
  margin-bottom: 40px;
}

.static-banner-content .gp-btn {
  padding: 14px 40px;
  font-size: 16px;
  text-transform: uppercase;
  background: transparent;
  border: 1px solid #FFF;
}

.static-banner-content .gp-btn:hover {
  border-color: transparent;
}

.static-banner-content .btn-active {
  margin-left: 20px;
  background: #00baa2;
  border-color: transparent;
}

.static-banner-content .btn-active:hover {
  color: #000;
  border-color: transparent;
}

.static-banner-content .btn-active:hover:before {
  background: #FFF;
}

/*--------------------------------------------------------------
  ##  About
  --------------------------------------------------------------*/
#about {
  padding: 100px 0 40px 0;
  background: #f5f5f5;
}

#about.about-us-two {
  padding: 100px 0;
}

#about.about-us-two .about-content p {
  margin-bottom: 30px;
}

.about-me h2 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 26px;
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 15px;
}

.about-me h2:after {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  width: 50px;
  height: 3px;
  background: #00baa2;
}

.about-me .ab-content {
  margin-bottom: 50px;
  font-size: 16px;
}

.feature-item-one {
  margin-bottom: 50px;
}

.feature-item-one .feature-icon {
  float: left;
  height: 40px;
  width: 40px;
  color: #00baa2;
}

.feature-item-one .feature-icon i {
  font-size: 30px;
}

.feature-item-one .content {
  margin-left: 40px;
}

.feature-item-one .content h4 {
  font-weight: 700;
  font-size: 20px;
}

.feature-item-one .content p {
  font-size: 14px;
}

.ab-thumb {
  margin: 0 auto;
  position: relative;
  z-index: 999;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
}

.ab-thumb:before {
  position: absolute;
  content: '';
  height: 450px;
  width: 500px;
  border: 5px solid #dcdcdc;
  right: -20px;
  bottom: -20px;
  z-index: -2;
}

.ab-thumb img {
  width: 100%;
  height: auto;
  z-index: 100;
}

.about-thumb img {
  width: 100%;
}

/*=========  About Us  ==========*/
.caption {
  text-align: center;
}

.caption h2 {
  font-size: 40px;
  font-weight: 300;
  line-height: 50px;
  color: #333;
  margin-bottom: 50px;
}

.feature-item-three {
  text-align: center;
  margin-bottom: 50px;
}

.feature-item-three h3 {
  font-size: 26px;
  font-weight: 700;
  color: #333;
  margin-bottom: 20px;
}

.feature-item-three .service-icon {
  margin-bottom: 20px;
}

.feature-item-three .service-icon i {
  color: #00baa2;
  font-size: 50px;
}

/*--------------------------------------------------------------
  ##  About Me
  --------------------------------------------------------------*/
#about-me {
  padding-top: 100px;
  background: #f3f3f3;
}

.my-photo img {
  width: 100%;
}

.profile-info {
  padding-bottom: 15px;
  border-bottom: 2px solid #00baa2;
  margin-bottom: 20px;
}

.profile-info .profile-title {
  font-size: 30px;
  text-transform: uppercase;
  color: #00baa2;
  font-weight: 700;
  margin: 0;
}

.profile-info .profile-position {
  font-size: 20px;
  margin: 0;
}

.profile-list {
  margin: 0 0 35px 0;
  padding: 0;
  list-style: none;
}

.profile-list li {
  margin-bottom: 15px;
}

.profile-list li .title {
  width: 150px;
  float: left;
  font-size: 17px;
  font-weight: 800;
  margin-bottom: 0;
  color: #131313;
}

.profile-list li .title span {
  float: right;
}

.profile-list li .cont {
  color: #757575;
  font-size: 18px;
  margin-left: 15px;
}

.social-link {
  padding: 20px;
  background: #00baa2;
  text-align: center;
}

.social-link .profile-social-link {
  margin: 0;
  padding: 0;
  list-style: none;
}

.social-link .profile-social-link li {
  display: inline-block;
  margin-right: 20px;
}

.social-link .profile-social-link li a {
  color: #FFF;
  font-size: 20px;
}

.dwn-btn {
  padding: 13px 0;
  width: 200px;
  text-align: center;
  border: 1px solid #333;
  font-size: 15px;
  color: #333;
  display: inline-block;
  border-radius: 30px;
  font-weight: 600;
}

.dwn-btn i {
  margin-right: 10px;
  font-size: 20px;
  vertical-align: middle;
}

.dwn-btn:hover {
  background: #333;
  color: #FFF;
  text-decoration: none;
}

.hire-btn {
  margin-left: 20px;
  background: #00baa2;
  border-color: #00baa2;
  color: #FFF;
}

.hire-btn:hover {
  border-color: #333;
}

/*--------------------------------------------------------------
  ##  Exprience
  --------------------------------------------------------------*/
#experience {
  padding: 100px 0;
  text-align: center;
}

.bs-exprience {
  padding: 70px 0;
  background: #00baa2;
}

.bs-exprience h3 {
  color: #FFF;
  font-weight: 300;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 30px;
}

.bs-exprience h3 span {
  font-size: 30px;
  font-weight: 700;
}

.bs-exprience .gp-btn {
  display: inline-block;
  padding: 12px 30px;
  border-radius: 30px;
}

.bs-exprience .gp-btn:hover {
  color: #000;
}

.bs-exprience .gp-btn:hover:before {
  background: #FFF;
}

/*--------------------------------------------------------------
  ##  Pricing
  --------------------------------------------------------------*/
#pricing {
  padding: 100px 0;
  background: #f8f8f8;
}

.pricing-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
  flex: 0 1 330px;
  max-width: 400px;
  margin: 0 auto 20px;
}

.pricing-feature-list {
  text-align: left;
}

.pricing-action:focus {
  outline: none;
}

.gp-pricing .pricing-item {
  padding: 0 0 2em;
  color: #fff;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05), 0 15px 30px -10px rgba(0, 0, 0, 0.3);
  transition: background 0.3s;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.gp-pricing .pricing-item .pricing__sentence {
  margin-bottom: 4em;
  color: #f5f5f5;
}

.gp-pricing .pricing-item .pricing-price {
  font-size: 2em;
  font-weight: bold;
  position: relative;
  z-index: 10;
  overflow: hidden;
  padding: 0.75em;
  cursor: default;
  color: #00baa2;
  background: #1a181b;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.gp-pricing .pricing-item.pricing-dark {
  background: #1F1F1F;
}

.gp-pricing .pricing-item.pricing-dark .pricing-title {
  color: #FFF;
}

.gp-pricing .pricing-item.pricing-dark:hover {
  background: #141315;
}

.gp-pricing .pricing-item.pricing-light {
  background-color: #FFF;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.03), 0 15px 30px -10px rgba(0, 0, 0, 0.1);
}

.gp-pricing .pricing-item.pricing-light .pricing-title {
  color: #333;
}

.gp-pricing .pricing-item.pricing-light .pricing__sentence {
  color: #444;
}

.gp-pricing .pricing-item.pricing-light .pricing-feature-list li i {
  color: #333;
  font-size: 15px;
}

.gp-pricing .pricing-item.pricing-light .pricing-action {
  background: transparent;
  color: #000;
  border: 1px solid #000;
  padding: 1em;
  margin: 0 5em;
  border-radius: 0;
  font-size: 14px;
}

.gp-pricing .pricing-item.pricing-light .pricing-action:hover {
  background: #00baa2;
  color: #FFF;
  border-color: #00baa2;
}

.gp-pricing .pricing-item .pricing-title {
  font-size: 2.35em;
  font-weight: 900;
  line-height: 1;
  width: 290px;
  margin: 0 auto;
  padding: 1em 1em 0em;
  text-transform: uppercase;
}

.gp-pricing .pricing-item .pricing__amp {
  padding: 0.15em 0 0.1em;
  color: #0f0e0f;
}

.gp-pricing .pricing-item:hover .pricing-price {
  color: #fff;
}

.gp-pricing .pricing-price::before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: #00baa2;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  -webkit-transform: translate3d(-150%, 0, 0) skewX(40deg);
  transform: translate3d(-150%, 0, 0) skewX(40deg);
}

.gp-pricing .pricing-item:hover .pricing-price::before {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0) skewX(0deg);
  transform: translate3d(0, 0, 0) skewX(0deg);
}

.gp-pricing .pricing__period {
  font-size: 0.5em;
  font-weight: normal;
  display: block;
  color: #848484;
}

.gp-pricing .pricing-feature-list {
  margin: 0;
  padding: 2em 5em;
  list-style: none;
  color: #6a6563;
}

.gp-pricing .pricing-feature-list li {
  font-size: 16px;
  font-weight: 700;
}

.gp-pricing .pricing-feature-list li i {
  color: #FFF;
  margin-right: 10px;
}

.pricing-action {
  color: inherit;
  border: none;
  background: none;
  font-weight: bold;
  margin: 0 2em;
  padding: 1em 2em;
  border-radius: 4px;
  background: #00baa2;
  transition: background-color 0.3s, color 0.3s;
}

.pricing-action:hover, .pricing-action:focus {
  color: #ef7d46;
  background: #fff;
}

/*--------------------------------------------------------------
  ##  Pricing
  --------------------------------------------------------------*/
#blog {
  padding: 100px 40px;
}

.blog-post {
  background: #1e1c20;
  margin: 0 auto 20px;
  max-width: 400px;
}

.blog-post.blog-post-light {
  background-color: transparent;
  border: 1px solid #f3f3f3;
}

.blog-post.blog-post-light .blog-post-content .entry-title a {
  color: #333;
}

.blog-post.blog-post-light .blog-post-content .entry-title a:hover {
  color: #00baa2;
}

.blog-post.blog-post-light .blog-post-content p {
  color: #444;
}

.blog-post.blog-post-light .blog-post-content .post-meta li a i {
  color: #333;
}

.blog-post img {
  width: 100%;
}

.blog-post-content {
  padding: 20px;
}

.blog-post-content .entry-title {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
}

.blog-post-content .entry-title a {
  text-decoration: none;
  color: #FFF;
  transition: all 0.3s ease-in-out;
}

.blog-post-content .entry-title a:hover {
  color: #00baa2;
}

.post-meta {
  margin: 0 0 20px 0;
  padding: 0;
  list-style: none;
}

.post-meta li {
  display: inline-block;
  margin-right: 10px;
}

.post-meta li a {
  color: #999;
}

.post-meta li a i {
  margin-right: 5px;
  color: #FFF;
}

.post-meta li a:hover {
  color: #00baa2;
  text-decoration: none;
}

.post-content {
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
}

.post-content p {
  color: #f5f5f5;
}

.post-footer {
  margin-top: 10px;
}

.post-footer .tag {
  display: inline-block;
}

.post-footer .tag span {
  margin-right: 5px;
  color: #FFF;
}

.post-footer .tag a {
  display: inline-block;
  margin-right: 5px;
  text-decoration: none;
  color: #bbb;
}

.post-footer .post-status {
  margin: 0;
  padding: 0;
  list-style: none;
  float: right;
}

.post-footer .post-status li {
  display: inline-block;
  margin-left: 10px;
}

.post-footer .post-status li a {
  color: #bbb;
  text-decoration: none;
}

.post-footer .post-status li a i {
  margin-right: 5px;
}

.post-footer .post-status li a:hover {
  color: #00baa2;
}

.read-more-btn {
  color: #000;
  display: block;
}

.read-more-btn:hover, .read-more-btn:focus {
  color: #00baa2;
  text-decoration: none;
}

/*=========  Blog Single  ==========*/
#blog-single {
  padding: 100px 0;
  background: #f5f5f5;
}

.blog-full .blog-thumb img {
  width: 100%;
}

.blog-full .post-meta {
  margin-top: 20px;
  margin-bottom: 10px;
}

.blog-full .post-meta li {
  font-weight: 600;
}

.blog-full .post-meta li i {
  margin-right: 5px;
  font-size: 16px;
}

.blog-full .post-meta li a {
  margin: 0 5px;
}

.blog-content .news-title {
  color: #000;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 20px;
}

.blog-content p {
  color: #222;
  font-weight: 400;
  margin-bottom: 20px;
}

.blockquote {
  background: rgba(0, 186, 162, 0.4);
  padding: 40px 20px;
  border-left: none;
}

.blockquote p {
  font-size: 20px;
  font-style: italic;
  font-weight: 400;
  margin-bottom: 0;
}

.comment-area {
  margin-top: 30px;
}

.comment-area h3 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 24px;
  margin: 20px 0;
}

.comment-area .dt-comments {
  padding-left: 0;
}

.comment-area ul {
  margin: 0;
  list-style: none;
}

.comment-content {
  background: #fff;
  padding: 15px 10px;
  margin-bottom: 15px;
}

.comment-content .avatar {
  height: 70px;
  width: 70px;
  border: 1px solid #222;
  padding: 3px;
  float: left;
  margin-right: 10px;
}

.comment-content .avatar img {
  width: 100%;
}

.comment-content .comment-header .author a {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  text-transform: uppercase;
  margin-right: 20px;
  text-decoration: none;
  position: relative;
}

.comment-content .comment-header .author a:after {
  position: absolute;
  content: '/';
  right: -15px;
}

.comment-content .comment-header .author a:hover {
  color: #00baa2;
}

.comment-content .comment-header .time-ago a {
  font-size: 13px;
  color: #333;
  text-decoration: none;
}

.comment-content .comment-header .time-ago a:hover {
  color: #00baa2;
}

.comment-content .post-message {
  margin-left: 80px;
}

.comment-content .reply {
  float: right;
}

.comment-content .reply a {
  padding: 7px 15px;
  color: #FFF;
  background: #333;
  text-decoration: none;
}

.comment-content .reply a:hover {
  background: #00baa2;
  color: #FFF;
}

.form-leave-comment form input, .form-leave-comment form textarea {
  width: 100%;
  border: 1px solid #d4d4d4;
  margin-bottom: 15px;
  padding: 6px 15px;
  outline: none;
}

.form-leave-comment form input:focus, .form-leave-comment form textarea:focus {
  border-color: #00baa2;
}

.form-leave-comment form input {
  height: 50px;
}

.form-leave-comment form textarea {
  height: 150px;
}

.form-leave-comment .gp-btn {
  padding: 12px 40px;
  font-size: 16px;
  text-transform: uppercase;
}

/*--------------------------------------------------------------
  ##  Testimonial
  --------------------------------------------------------------*/
#testimonial {
  padding: 100px 0;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
}

#testimonial .section-title {
  margin-bottom: 50px;
}

#testimonial .section-title h5 {
  color: #FFF;
}

#testimonial .section-title h2 {
  color: #FFF;
}

.testimonial {
  position: relative;
}

.testimonial .tm-control {
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.testimonial .tm-control .tm-prev, .testimonial .tm-control .tm-next {
  height: 35px;
  width: 35px;
  border: 1px solid #FFF;
  text-align: center;
  line-height: 40px;
  transition: all 0.3s ease;
  position: absolute;
  top: 30%;
  z-index: 1111;
  cursor: pointer;
}

.testimonial .tm-control .tm-prev i, .testimonial .tm-control .tm-next i {
  font-size: 25px;
  color: #FFF;
}

.testimonial .tm-control .tm-prev:hover, .testimonial .tm-control .tm-next:hover {
  background: #00baa2;
  border-color: #00baa2;
}

.testimonial .tm-control .tm-next {
  right: -35px;
}

.testimonial .tm-control .tm-prev {
  left: -35px;
}

.testimonial:hover .tm-control {
  opacity: 1;
}

.testimonial:hover .tm-next {
  right: 0;
}

.testimonial:hover .tm-prev {
  left: 0;
}

.testimonial-slider {
  text-align: center;
}

.testi-content p {
  color: #FFF;
  font-size: 16px;
  letter-spacing: 0.5px;
  font-family: "Raleway", sans-serif;
  width: 60%;
  margin: 0 auto;
  margin-bottom: 20px;
}

.testi-content i {
  font-size: 40px;
  color: #f1f1f1;
}

.testi-content h4 {
  color: #00baa2;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 700;
}

.testi-content span {
  color: #FFF;
  font-weight: 600;
  margin-bottom: 20px;
  display: block;
}

.testi-content .avatar {
  height: 100px;
  width: 100px;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 20px;
}

.testi-content .avatar img {
  width: 100%;
  border-radius: 50%;
}

.slider-pagination {
  text-align: center;
  margin-top: 25px;
}

.slider-pagination .swiper-pagination {
  position: relative;
}

.swiper-pagination .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  margin: 0 3px;
  background: #565656;
  -webkit-backface-visibility: visible;
  transition: opacity .2s ease;
  border-radius: 0;
}

.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active, .swiper-pagination .swiper-pagination-bullet:hover {
  background: #00baa2;
}

.testmonial-two .testi-content {
  border: 1px solid #333;
  text-align: left;
  padding: 20px 20px 40px 20px;
}

.testmonial-two .testi-content p {
  width: 100%;
  margin-bottom: 0;
}

.testmonial-two .client-details {
  text-align: left;
  margin-top: -30px;
  margin-left: 20px;
}

.testmonial-two .client-details .avatar {
  height: 100px;
  width: 100px;
  border: 5px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 10px;
}

.testmonial-two .client-details .avatar img {
  width: 100%;
}

.testmonial-two .client-details h4 {
  color: #00baa2;
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 600;
}

.testmonial-two .client-details span {
  color: #FFF;
}

/*--------------------------------------------------------------
  ##  Portfolio
  --------------------------------------------------------------*/
#portfolio {
  padding: 100px 40px;
  position: relative;
  background: #f5f5f5;
}

#portfolio.portfolio-three {
  padding: 100px 0;
}

#portfolio .title_right {
  right: 0;
}

#portfolio .switch_right {
  right: 6%;
}

.portfolio-filter, .portfolio-filter-two {
  margin: 30px 0 0 0;
  padding-left: 15px;
  list-style: none;
  margin-bottom: 30px;
}

.portfolio-filter li, .portfolio-filter-two li {
  display: inline-block;
  margin-right: 15px;
}

.portfolio-filter li a, .portfolio-filter-two li a {
  padding: 10px 22px;
  border: 1px solid #000;
  color: #000;
  font-size: 13px;
  text-transform: uppercase;
  line-height: 10px;
}

.portfolio-filter li a:hover, .portfolio-filter li a:focus, .portfolio-filter li a.active, .portfolio-filter-two li a:hover, .portfolio-filter-two li a:focus, .portfolio-filter-two li a.active {
  color: #FFF;
  background: #00baa2;
  border-color: transparent;
  text-decoration: none;
}

.gp-portfolio-gallery, .gp-portfolio-gallery-two {
  margin-bottom: 40px;
}

.gp-portfolio-item {
  background: #00baa2;
  position: relative;
  overflow: hidden;
  margin: 4px 0.2%;
  height: auto;
  text-align: center;
  cursor: pointer;
  float: left;
}

.gp-portfolio-item img {
  width: 100%;
  opacity: 0.95;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale3d(1.05, 1.05, 1);
  transform: scale3d(1.05, 1.05, 1);
}

.gp-portfolio-item.width-2-1 {
  width: 50%;
}

.gp-portfolio-item .inner {
  position: relative;
  overflow: hidden;
}

.gp-portfolio-item .inner:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  content: '';
  -webkit-transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
  transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
}

.gp-portfolio-item .portfolio-overlay {
  opacity: 0;
  position: relative;
  transform: scale(1.5);
  transition: all 0.3s ease-in-out;
}

.gp-portfolio-item .portfolio-overlay .content {
  position: absolute;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
  width: 100%;
}

.gp-portfolio-item .portfolio-overlay .content h4 {
  color: #FFF;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}

.gp-portfolio-item .portfolio-overlay .content a {
  color: #000;
  text-decoration: none;
  font-style: italic;
}

.gp-portfolio-item:before, .gp-portfolio-item:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #FFF;
  transition: all .5s ease-in-out;
  z-index: 12;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.gp-portfolio-item:before {
  height: 0;
  width: 2px;
}

.gp-portfolio-item:after {
  width: 0;
  height: 2px;
}

.gp-portfolio-item:hover:before {
  height: 40px;
  opacity: 1;
}

.gp-portfolio-item:hover:after {
  width: 40px;
  opacity: 1;
}

.gp-portfolio-item:hover img {
  opacity: 0.3;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

.gp-portfolio-item:hover .inner:before {
  -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0);
  transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0);
}

.gp-portfolio-item:hover .portfolio-overlay {
  opacity: 1;
  transform: scale(1);
}

.lg-backdrop {
  background-color: rgba(0, 0, 0, 0.9);
}

.width-1-4 {
  width: 19.65%;
}

.width-1-2 {
  width: 39.35%;
}

.gp-portfolio-item h2 {
  text-align: left;
}

.gp-portfolio-item:hover .inner::before {
  -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0);
  transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0);
}

.gp-portfolio-gallery-two .gp-portfolio-item {
  margin: 0;
}

/*--------------------------------------------------------------
  ##  Choose
  --------------------------------------------------------------*/
#my-skills {
  padding: 100px 0;
}

.skills {
  margin-top: 50px;
}

.skills-wrapper {
  max-width: 500px;
  margin: 0 auto;
}

.skills-thumb img {
  width: 100%;
}

.skills-content p {
  color: #000;
  margin-bottom: 80px;
}

.progress {
  border-radius: 0;
  height: 15px;
  margin-bottom: 40px;
  overflow: visible;
  box-shadow: none;
}

.skill-bar {
  text-align: left !important;
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.skill-bar .skill-bar-wrap {
  margin-bottom: 20px;
  box-shadow: none;
}

.skill-bar .progress-bar {
  height: 15px;
  width: 0;
  display: block;
  text-align: right;
  overflow: visible !important;
  position: relative;
  box-shadow: none;
  border-radius: 0 !important;
  opacity: 1;
  background: #00baa2;
}

.skill-bar .progress-bar:after {
  position: absolute;
  top: -8px;
  right: 5px;
  content: '';
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 8px solid #00baa2;
}

.skill-bar .progress-bar .skill-name, .skill-bar .progress-bar .percentage {
  color: #000;
  position: relative;
  top: -25px;
}

.skill-bar .progress-bar .skill-name {
  position: absolute;
  left: 0;
  font-weight: 700;
  font-size: 14px;
}

/*=========  Choose Two  ==========*/
#choose-us {
  background: #f0f0f0;
}

.choose-item {
  padding: 50px 0 0;
}

.feature-item-four:nth-child(1), .feature-item-four:nth-child(2) {
  margin-bottom: 40px;
}

.feature-item-four .feature-icon {
  height: 55px;
  width: 55px;
  background: #00baa2;
  border-radius: 50%;
  text-align: center;
  margin-bottom: 10px;
  line-height: 61px;
}

.feature-item-four .feature-icon i {
  font-size: 25px;
  color: #FFF;
}

/*--------------------------------------------------------------
  ##  Countup
  --------------------------------------------------------------*/
.countup {
  padding: 70px 0;
  background-size: cover;
}

.countup .section-title h2 {
  color: #FFF;
}

.counter-box i {
  font-size: 50px;
  color: #FFF;
}

.counter-box h2 {
  text-transform: uppercase;
  font-weight: 800;
  color: #FFF;
  font-size: 50px;
  margin-bottom: 0;
}

.counter-box p {
  color: #f5f5f5;
}

/*--------------------------------------------------------------
  ##  Service
  --------------------------------------------------------------*/
#service {
  padding: 100px 0 40px;
  background: #1f1f1f;
}

.service-item {
  box-shadow: 0 15px 40px rgba(35, 32, 39, 0.5);
  margin-bottom: 50px;
  cursor: pointer;
  max-width: 360px;
  margin: 0 auto 30px;
}

.service-item .service-thumb {
  position: relative;
}

.service-item .service-thumb img {
  width: 100%;
}

.service-item .service-thumb .service-header {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.75);
  overflow: hidden;
}

.service-item .service-thumb .service-header:before {
  position: absolute;
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;
  border: 5px solid #fff;
  box-shadow: 0 0 0 30px rgba(255, 255, 255, 0.2);
  content: '';
  opacity: 1;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: all 0.3s ease-in-out;
  transform: scale3d(1, 1, 1);
}

.service-item .service-thumb .service-header .service-title {
  position: absolute;
  bottom: 40px;
  left: 40px;
  transition: all 0.3s ease-in-out;
}

.service-item .service-thumb .service-header .service-title h3 {
  margin-bottom: 0;
  color: #FFF;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 22px;
  transform: translate(0px, 30px);
  transition: all 0.3s ease-in-out;
}

.service-item .service-thumb .service-header .service-title span {
  font-size: 16px;
  color: #FFF;
  transform: translate(0px, 30px);
  transition: all 0.5s ease-in-out;
  position: relative;
  display: block;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.service-item .service-thumb .service-header .service-title span:after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 3px;
  width: 0px;
  background: #00baa2;
  content: '';
  transition: all 0.3s ease-in-out;
}

.service-item .service-thumb .service-header .service-content {
  opacity: 0;
  transform: translate(0px, 30px) scale(0);
  transition: all 0.7s ease-in-out;
}

.service-item .service-thumb .service-header .service-content p {
  font-size: 16px;
  font-weight: 700;
  color: #FFF;
}

.service-item:hover {
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.12);
}

.service-item:hover .service-header {
  transform: translateZ(20px);
}

.service-item:hover .service-header:before {
  border: 2px solid #FFF;
}

.service-item:hover .service-header .service-title h3 {
  transform: translate(0, -20px);
}

.service-item:hover .service-header .service-title span {
  transform: translate(0, -20px);
}

.service-item:hover .service-header .service-title span:after {
  width: 100px;
}

.service-item:hover .service-header .service-content {
  opacity: 1;
  transform: translate(0, -20px) scale(1);
}

#service-two {
  margin-top: 80px;
}

.feature-item-two {
  background: #383838;
  padding: 60px 25px 30px;
  position: relative;
  text-align: center;
  transition: all 0.3s ease-in-out;
  max-width: 400px;
  margin: 0 auto 70px;
}

.feature-item-two h3 {
  color: #00baa2;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 600;
}

.feature-item-two p {
  color: #f3f3f3;
}

.feature-item-two .service-icon {
  height: 70px;
  width: 70px;
  border: 1px solid #00baa2;
  margin: 0 auto;
  position: absolute;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.15s ease-in-out;
}

.feature-item-two .service-icon i {
  position: absolute;
  z-index: 100;
  font-size: 35px;
  color: #FFF;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.feature-item-two .service-icon:before {
  position: absolute;
  content: '';
  margin: auto;
  height: 60px;
  width: 60px;
  background: #00baa2;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  transition: all 0.15s ease-in-out;
}

.feature-item-two:hover {
  background: #111;
  transform: translateY(-10px);
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.12);
}

.feature-item-two:hover .service-icon:before {
  height: 69px;
  width: 69px;
}

.feature-item-two:hover p {
  color: #FFF;
}

/*=========  Service Four  ==========*/
#service-four {
  padding: 70px 0;
}

#service-four .skill-bar {
  margin-top: 70px !important;
}

.feature-item-five {
  float: left;
  width: 180px;
  height: 180px;
  background: #f7f7f7;
  text-align: center;
  padding: 58px 0;
  transition: all 0.3s ease-in-out;
}

.feature-item-five:nth-child(1), .feature-item-five:nth-child(3), .feature-item-five:nth-child(5) {
  background: #f7f7f7;
}

.feature-item-five:nth-child(2), .feature-item-five:nth-child(4), .feature-item-five:nth-child(6) {
  background: #eff4f3;
}

.feature-item-five .fea-icon {
  margin-bottom: 15px;
}

.feature-item-five .fea-icon i {
  font-size: 30px;
}

.feature-item-five h4 {
  font-size: 16px;
  font-weight: 300;
  color: #1d1d1a;
}

.feature-item-five:hover {
  background: #00baa2;
}

.feature-item-five:hover .fea-icon i {
  color: #FFF;
}

.feature-item-five:hover .content h4 {
  color: #FFF;
}

.service-title {
  margin-bottom: 30px;
}

.service-title h3 {
  font-size: 26px;
  text-transform: uppercase;
  font-weight: 700;
  color: #333;
  position: relative;
  padding-bottom: 10px;
}

.service-title h3:after {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  background: #00baa2;
  height: 2px;
  width: 50px;
}

/*=========  Service-five  ==========*/
#service-five {
  margin-top: 50px;
}

#service-five .feature-item-three h3 {
  color: #FFF;
}

#service-five .feature-item-three p {
  color: #cccccc;
}

/*--------------------------------------------------------------
  ##  Video
  --------------------------------------------------------------*/
#video {
  padding: 150px 0;
  text-align: center;
}

#video p {
  color: #ccc;
  font-weight: 600;
  font-size: 16px;
}

.video-play-icon {
  display: block;
  margin: 0 auto;
  width: 60px;
  height: 60px;
  line-height: 20px;
  font-size: 6rem;
  background-color: #00baa2;
  border-radius: 50%;
  box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.2);
}

.video-play-icon:focus {
  outline: none;
}

.how-it-works .video-play-icon {
  margin-top: 30px;
}

.video-play-icon i {
  margin-left: 9px;
  font-size: 30px;
  color: #FFF;
}

.intro-title {
  text-transform: uppercase;
  color: #FFF;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 15px;
}

/*--------------------------------------------------------------
  ##  Team
  --------------------------------------------------------------*/
#team {
  padding: 100px 0;
  background: #181818;
  overflow: hidden;
}

.team-member {
  position: relative;
  cursor: pointer;
  max-width: 400px;
  margin: 0 auto 20px;
}

.team-member .member-img {
  position: relative;
  overflow: hidden;
}

.team-member .member-img img {
  width: 100%;
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
}

.team-member .profail {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.team-member .profail .content {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 20px 50px;
}

.team-member .profail .content p {
  color: #FFF;
  font-weight: 600;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  transform: translateY(20px);
  opacity: 0;
}

.team-member .team-profail {
  margin-bottom: 20px;
  padding: 0 0 20px 0;
  list-style: none;
  position: relative;
}

.team-member .team-profail:after {
  position: absolute;
  left: 50%;
  bottom: 0;
  content: '';
  height: 3px;
  width: 0;
  transition: all 0.3s ease-in-out;
  background: #00baa2;
  transform: translateX(-50%);
}

.team-member .team-profail li {
  height: 40px;
  width: 40px;
  background: #FFF;
  line-height: 40px;
  display: inline-block;
  text-align: center;
  margin: 0 2px;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
}

.team-member .team-profail li a {
  color: #000;
  font-size: 20px;
}

.team-member .team-profail li:hover {
  background: #00baa2;
}

.team-member .team-profail li:hover a {
  color: #FFF;
}

.team-member:hover .member-img img {
  transform: scale(1);
}

.team-member:hover .profail {
  opacity: 1;
}

.team-member:hover .content p {
  transform: translateY(0);
  opacity: 1;
}

.team-member:hover .team-profail:after {
  width: 250px;
}

.name {
  text-align: center;
  background: #FFF;
  padding: 15px 0;
}

.name h3 {
  color: #00baa2;
  font-weight: 700;
  margin: 0;
  font-size: 24px;
}

.name p {
  margin-bottom: 0;
  font-size: 16px;
}

/*--------------------------------------------------------------
  ##  Brand
  --------------------------------------------------------------*/
#brand {
  padding: 100px 0;
  background: #00baa2;
}

.brand-thumb {
  border: 1px solid #FFF;
  text-align: center;
  background: rgba(255, 255, 255, 0.5);
}

.brand-thumb img {
  width: 100%;
}

/*--------------------------------------------------------------
  ##  Contact
  --------------------------------------------------------------*/
#contact {
  padding: 100px 0;
  background: #f5f5f5;
}

#contact h2 {
  color: #000;
  margin-bottom: 40px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 700;
  position: relative;
  padding-bottom: 10px;
  margin-top: 0;
}

#contact h2:after {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  width: 80px;
  height: 4px;
  background: #00baa2;
}

.address-box .info {
  color: #000;
  margin-bottom: 50px;
}

.address-info {
  margin: 0;
  padding: 0;
  list-style: none;
}

.address-info h4 {
  margin-left: 50px;
  font-size: 18px;
  font-weight: 700;
  position: relative;
  padding-bottom: 7px;
  margin-bottom: 5px;
}

.address-info h4:after {
  position: absolute;
  content: '';
  height: 3px;
  width: 50px;
  left: 0;
  bottom: 0;
  background: #00baa2;
}

.address-info li {
  padding: 5px 0;
  margin-bottom: 20px;
}

.address-info li p {
  margin-bottom: 0;
}

.address-info li .info-icon {
  height: 40px;
  width: 40px;
  line-height: 43px;
  background: #00baa2;
  border-radius: 50%;
  float: left;
  text-align: center;
}

.address-info li .info-icon i {
  color: #FFF;
  font-size: 18px;
}

.address-info li .info-details {
  margin-left: 50px;
}

.address-info li .info-details p {
  font-size: 16px;
}

#ajax-form {
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: 10px;
  line-height: 14px;
  color: #101010;
  margin: 0 auto;
}

#ajax-form label {
  display: block;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 30px;
}

#ajax-form input {
  position: relative;
  width: calc(100% - 40px);
  padding: 10px 15px;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 14px;
  line-height: 24px;
  border: none;
  color: #737373;
  -webkit-transition: border-bottom 0.3s, color 0.3s;
  transition: border-bottom 0.3s, color 0.3s;
  margin-bottom: 20px;
}

#ajax-form textarea,
#ajax-form input {
  width: 100%;
  background: rgba(50, 50, 50, 0.1);
  height: 45px;
  border: none;
  border-radius: 0;
  outline: none;
  box-shadow: none;
  color: #000;
  transition: all 0.1s ease-in-out;
  border: 1px solid transparent;
}

#ajax-form textarea:focus, #ajax-form textarea:hover,
#ajax-form input:focus,
#ajax-form input:hover {
  color: #fff;
  border: 1px solid #066b5e;
}

#ajax-form textarea {
  padding-right: 20px;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 14px;
  line-height: 24px;
  height: 150px;
  color: #737373;
  -webkit-transition: border-bottom 0.3s, color 0.3s;
  transition: border-bottom 0.3s, color 0.3s;
  margin-bottom: 15px;
  padding: 10px 20px;
}

.error {
  font: 11px/22px 'Open Sans', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: none;
  color: #636363;
}

#ajaxsuccess {
  background: #f2f2f2;
  color: #101010;
  height: 60px;
  display: none;
  padding-left: 10px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

.text-align-center {
  text-align: center;
  padding-top: 10px;
}

#ajax-form textarea:active,
#ajax-form input:active {
  color: #101010;
}

#ajax-form textarea:active,
#ajax-form input:hover {
  color: #101010;
}

#ajax-form textarea:focus,
#ajax-form input:focus {
  outline: none !important;
}

#ajax-form .massage-two textarea {
  height: 200px;
}

#ajax-form input:-ms-input-placeholder {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #999999;
}

#ajax-form input::-moz-placeholder {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #999999;
}

#ajax-form input:-moz-placeholder {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #999999;
}

#ajax-form input::-webkit-input-placeholder {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #999999;
}

#ajax-form textarea:-ms-input-placeholder {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #999999;
}

#ajax-form textarea::-moz-placeholder {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #999999;
}

#ajax-form textarea:-moz-placeholder {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #999999;
}

#ajax-form textarea::-webkit-input-placeholder {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #999999;
}

#ajax-form button {
  background: #323232;
  color: #fff;
  font-size: 14px;
  padding: 15px 25px;
  max-width: 300px;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 1px;
}

#ajax-form button:hover {
  color: #fff;
}

#ajax-form button:focus,
#ajax-form button:active {
  border: none;
  outline: none;
}

.button-effect {
  min-width: 150px;
  max-width: 200px;
  display: block;
  margin: 0 auto;
  border: none;
  background: none;
  color: inherit;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
}

.button-effect:focus {
  outline: none;
}

.button-effect > span {
  vertical-align: middle;
}

#ajax-form button[type=submit].clicked > span, #ajax-form button[type=submit] > i {
  display: none;
}

#map .gmap3-area {
  margin-bottom: 0;
  height: 500px;
}

/*--------------------------------------------------------------
  ##  footer
  --------------------------------------------------------------*/
#footer {
  background: #000;
  overflow: hidden;
}

#footer .footer-inner {
  padding: 60px 0;
  background: #131313;
}

#footer .footer-item {
  width: 33%;
  float: left;
}

#footer .footer-item .copyright {
  color: #FFF;
  font-size: 16px;
  padding: 12px 40px;
}

#footer .footer-item .copyright p {
  margin-bottom: 0;
}

#footer .footer-item .footer-logo {
  padding: 4px 0;
}

#footer .footer-item .footer-logo a {
  display: inline-block;
}

.copyright {
  padding: 15px 0;
}

.gp-social-link {
  margin: 0;
  padding: 10px 20px;
  list-style: none;
}

.gp-social-link li {
  display: inline-block;
  height: 30px;
  width: 30px;
  line-height: 30px;
  background: rgba(50, 50, 50, 0.5);
  margin-right: 7px;
  transition: all 0.3s ease-in-out;
  text-align: center;
}

.gp-social-link li a {
  color: #FFF;
}

.gp-social-link li:hover {
  background: #00baa2;
}

.fotter-about p {
  color: #ccc;
}

.footer-logo {
  margin-bottom: 20px;
}

.footer-title {
  color: #00baa2;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 25px;
  position: relative;
  padding-bottom: 10px;
}

.footer-title:after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: '';
  background: #00baa2;
  height: 3px;
  width: 40px;
}

.footer-gallery .gallery-item {
  height: 80px;
  width: 80px;
  float: left;
  margin-right: 5px;
  margin-bottom: 5px;
}

.footer-gallery .gallery-item img {
  width: 100%;
  height: auto;
}

.footer-form p {
  color: #ccc;
  font-size: 16px;
}

.subscrib-form input {
  width: 80%;
  background: transparent;
  color: #FFF;
  height: 45px;
  border: 1px solid #565656;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 10px;
  outline: none;
}

.subscrib-form .gp-btn {
  padding: 10px 40px;
  text-transform: uppercase;
}

/*--------------------------------------------------------------
  ##  Page Banner
  --------------------------------------------------------------*/
#page-banner {
  padding: 150px;
  background-size: cover;
  text-align: center;
}

.page-heading {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  display: inline-block;
}

.page-heading h1 {
  color: #FFF;
  text-transform: uppercase;
  font-size: 50px;
  font-weight: 700;
}

.page-heading .page-link a {
  display: inline-block;
  color: #FFF;
  font-weight: 300;
  margin-right: 20px;
  position: relative;
}

.page-heading .page-link a:after {
  position: absolute;
  content: '/';
  right: -15px;
  top: 1px;
}

.page-heading .page-link a:last-child:after {
  display: none;
}

.page-heading .page-link a:hover {
  color: #00baa2;
  text-decoration: none;
}

/*--------------------------------------------------------------
  ##  Side-menu
  --------------------------------------------------------------*/
#side_menu {
  width: 300px;
  background: #000;
  position: fixed;
  top: 0;
  height: 100vh;
  padding: 40px 20px;
  z-index: 9999;
  overflow-y: auto;
}

.cbp-spmenu-right {
  right: -300px;
}

.cbp-spmenu-right.cbp-spmenu-open {
  right: 0;
}

.cbp-spmenu, .cbp-spmenu-push {
  transition: all 0.3s ease;
}

.cbp-spmenu-push-toleft {
  left: -300px;
}

.share {
  position: relative;
}

.share .social-share {
  position: absolute;
  margin: 0;
  padding: 0;
  list-style: none;
  left: 0;
  background: #000;
  top: 50px;
  transition: all 0.3s ease-in-out;
  opacity: 0;
}

.share .social-share li {
  text-align: center;
  border-bottom: 1px solid #272727;
  display: block;
}

.share .social-share li:last-child {
  border-bottom: none;
}

.share .social-share li a {
  padding: 2px 7px;
  display: block;
}

.share .social-share li a i {
  margin-right: 0;
}

.share .social-share li a:hover {
  background: #00baa2;
  color: #FFF;
}

.share:hover .social-share {
  opacity: 1;
  top: 35px;
}

.about-me img {
  border-radius: 50%;
  margin-bottom: 20px;
}

.my-details h4 {
  color: #00baa2;
}

.my-details h5 {
  color: #999;
  margin-top: 0;
}

.my-details p {
  color: #777;
  margin-bottom: 40px;
}

.side-menu {
  margin-bottom: 30px;
  padding: 20px 0;
  list-style: none;
  text-align: center;
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
}

.side-menu li a {
  color: #FFF;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  letter-spacing: 1px;
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  line-height: 26px;
}

.side-menu li a:after {
  position: absolute;
  content: '';
  background: #00baa2;
  height: 1px;
  width: 0;
  left: 0;
  bottom: 0;
  transition: all 0.3s ease-in-out;
}

.side-menu li a:hover {
  color: #00baa2;
  background-color: transparent;
}

.side-menu li a:hover:after {
  width: 100%;
}

.instagram-feed h4 {
  color: #FFF;
  margin-bottom: 10px;
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.instagram-feed h4:after {
  position: absolute;
  content: '';
  background: #00baa2;
  height: 3px;
  width: 100px;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  transition: all 0.3s ease-in-out;
}

.instagram-feed a {
  display: inline-block;
  height: 70px;
  width: 70px;
  margin: 5px 4px;
}

.instagram-feed a img {
  width: 100%;
}

/*--------------------------------------------------------------
  ##  Responsive
  --------------------------------------------------------------*/
@media screen and (max-width: 1400px) {
  /*=========  Portfolio  ==========*/
  .gp-portfolio-item .portfolio-overlay .content {
    bottom: 5px;
  }
  .gp-portfolio-item .portfolio-overlay .content h4 {
    font-size: 15px;
  }
}

@media screen and (max-width: 1024px) {
  /*=========  Header Menu  ==========*/
  .menu--shylock .menu__link {
    margin: 0 0.5em;
  }
  .shere-menu {
    margin-left: 7px;
  }
  /*=========  Banner  ==========*/
  .static-banner-content h4 {
    font-size: 24px;
  }
  .static-banner-content h2 {
    font-size: 65px;
    line-height: 70px;
  }
  /*=========  Portfolio  ==========*/
  .gp-portfolio-item .portfolio-overlay .content {
    bottom: 5px;
  }
  .gp-portfolio-item .portfolio-overlay .content h4 {
    font-size: 15px;
  }
  /*=========  Choose Thumb  ==========*/
  .choose-thumb {
    width: 496px;
    margin-top: 210px;
  }
  .choose-thumb img {
    width: 100%;
  }
  /*=========  Pricing  ==========*/
  .gp-pricing .pricing-feature-list {
    padding: 2em;
  }
}

@media screen and (max-width: 768px) {
  /*=========  Base  ==========*/
  .section-title h2 {
    font-size: 28px;
  }
  .sub-title br {
    display: none;
  }
  /*=========  Header Menu  ==========*/
  #header .header-wrapper {
    padding: 5px 0;
  }
  #header .top-menu {
    display: none;
  }
  .menu-list {
    display: none;
  }
  #header.navbar-small {
    height: 50px;
  }
  #header.navbar-small .header-wrapper {
    padding: 5px 0;
  }
  /*=========  Banner  ==========*/
  .banner .author-details h1 {
    font-size: 40px;
  }
  .banner .author-details h2 {
    font-size: 20px;
  }
  .banner .static-banner-content h4 {
    font-size: 20px;
  }
  .banner .static-banner-content h2 {
    font-size: 40px;
    line-height: 30px;
  }
  .banner .static-banner-content p {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 20px;
  }
  .banner .static-banner-content .gp-btn {
    padding: 10px 20px;
    font-size: 13px;
  }
  /*=========  About  ==========*/
  .ab-thumb:before {
    height: 370px;
    width: 390px;
  }
  .caption h2 {
    font-size: 24px;
    line-height: 30px;
  }
  .feature-item-three {
    max-width: 400px;
    margin: 0 auto 40px;
  }
  .about-thumb {
    margin-top: 20px;
  }
  .my-photo {
    max-width: 400px;
    margin: 0 auto 20px;
  }
  .my-photo img {
    width: 100%;
  }
  .profile-list {
    margin: 0 0 25px 0;
  }
  .profile-list li {
    margin-bottom: 10px;
  }
  .profile-list li .title {
    font-size: 15px;
  }
  .profile-list li .cont {
    font-size: 15px;
  }
  .dwn-btn {
    padding: 8px 0;
    width: 150px;
    font-size: 13px;
    margin-bottom: 20px;
  }
  /*=========  Exprience  ==========*/
  .bs-exprience {
    padding: 50px 0;
  }
  .bs-exprience h3 {
    margin-bottom: 20px;
  }
  /*=========  Service  ==========*/
  #service {
    padding: 50px 0;
  }
  /*=========  Portfolio  ==========*/
  #portfolio {
    padding: 50px 0;
  }
  .width-1-4 {
    width: 46%;
  }
  .width-1-2 {
    width: 46%;
  }
  .gp-portfolio-item {
    margin: 4px 1%;
  }
  /*=========  Choose Two  ==========*/
  .feature-item-four {
    margin: 0 auto 30px;
    max-width: 400px;
  }
  .choose-thumb {
    max-width: 400px;
    margin: 0 auto;
    width: 100%;
  }
  .choose-thumb img {
    width: 100%;
  }
  /*=========  Skills  ==========*/
  #my-skills {
    padding: 50px 0;
  }
  .skills {
    margin-top: 0;
  }
  .skills-thumb {
    max-width: 500px;
    margin: 0 auto 30px;
  }
  .skills-wrapper {
    margin: 20px 0 0;
  }
  .feature-item-one {
    width: 50%;
    border-bottom: 1px solid #e8eaea;
  }
  /*=========  CountUp  ==========*/
  .counter-box {
    margin-bottom: 30px;
  }
  /*=========  Pricing  ==========*/
  #pricing {
    padding: 50px 0;
  }
  /*=========  Team  ==========*/
  #team {
    padding: 50px 0;
  }
  /*=========  Blog  ==========*/
  #blog {
    padding: 50px 0;
  }
  /*=========  Contact  ==========*/
  #contact {
    padding: 50px 0;
  }
  #contact .gp-btn {
    margin-bottom: 20px;
  }
  /*=========  Footer  ==========*/
  #footer .footer-item .copyright {
    padding: 12px 0 0 20px;
  }
  .gallery {
    margin-bottom: 15px;
  }
  .gp-social-link {
    padding: 10px 0;
  }
  .copyright p {
    left: 18px;
  }
}

@media screen and (max-width: 500px) {
  /*=========  Banner  ==========*/
  .banner {
    height: 70vh;
  }
  .banner .author-details {
    width: 100%;
  }
  .banner .author-details h1 {
    font-size: 45px;
  }
  .banner .static-banner-content h4 {
    font-size: 16px;
  }
  .banner .static-banner-content h2 {
    font-size: 30px;
    line-height: 20px;
  }
  .banner .static-banner-content p {
    font-size: 12px;
  }
  .banner .static-banner-content .gp-btn {
    padding: 8px 15px;
  }
  /*=========  About  ==========*/
  .ab-thumb:before {
    height: 250px;
    width: 300px;
  }
  /*=========  Portfolio ==========*/
  .portfolio-filter, .portfolio-filter-two {
    padding-left: 0;
  }
  .portfolio-filter li, .portfolio-filter-two li {
    margin-right: 0;
  }
  .portfolio-filter li a, .portfolio-filter-two li a {
    padding: 10px;
    font-size: 10px;
  }
  .width-1-4 {
    width: 100%;
  }
  .width-1-2 {
    width: 100%;
  }
  /*=========  Testimonial  ==========*/
  #testimonial {
    padding: 50px 0;
  }
  .testi-content p {
    width: 100%;
  }
  .tm-control {
    display: none;
  }
  /*=========  Footer  ==========*/
  #footer .footer-item {
    width: 100%;
    float: none;
    text-align: center;
  }
}

/*--------------------------------------------------------------
  # Widgets
  --------------------------------------------------------------*/
/*--------------------------------------------------------------
  ## Search
  --------------------------------------------------------------*/
.widget_dt_search .search-form {
  width: 100%;
}

.widget_dt_search .search-form label {
  position: relative;
  width: 270px;
}

.widget_dt_search .search-form .search-icon {
  position: absolute;
  right: 6px;
  top: 12px;
}

.widget_dt_search input[type="search"] {
  width: 100%;
  border: 1px solid #eeeeee;
  outline: none;
  padding: 10px 20px;
  color: #111;
  font-size: 14px;
  font-size: 1em;
}

.widget_dt_search .search-submit {
  display: none;
}

/*=========  Author  ==========*/
#author {
  background: #00baa2;
  padding: 20px 15px;
  margin-bottom: 30px;
}

#author img {
  height: 115px;
  width: 115px;
  margin-bottom: 20px;
}

#author .my-details h4 {
  color: #000;
  font-size: 20px;
  font-weight: 700;
}

#author .my-details h5 {
  color: #f5f5f5;
}

#author .my-details p {
  margin-bottom: 0;
  color: #191919;
}

/*--------------------------------------------------------------
  ## Dt Custom Menu
  --------------------------------------------------------------*/
/* Custom Menu */
.widget {
  margin-bottom: 30px;
}

.widget .widget-title {
  position: relative;
  font-size: 20px;
  font-size: 1.42857em;
  color: #111;
  margin-top: 0;
  margin-bottom: 40px;
  padding-bottom: 15px;
  text-transform: uppercase;
  font-weight: 700;
}

.widget .widget-title:after {
  content: '';
  position: absolute;
  width: 50px;
  height: 3px;
  left: 0;
  bottom: 0;
  background: #00baa2;
}

.widget_nav_menu ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.widget_nav_menu ul li {
  padding: 10px 0;
  border-bottom: 1px solid #ededed;
}

.widget_nav_menu ul li:last-child {
  border-bottom: none;
}

.widget_nav_menu ul li a {
  color: #6d6c6c;
  text-decoration: none;
  transition: all 0.3s ease;
  font-weight: 600;
}

.widget_nav_menu ul li a:hover {
  color: #00baa2;
}

/*--------------------------------------------------------------
  ## Dt Tags
  --------------------------------------------------------------*/
/* Dt Tags */
.dt-tag a {
  color: #6d6c6c;
  border: 1px solid #d4d4d4;
  text-decoration: none;
  padding: 4px 8px;
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 5px;
  transition: all 0.3s ease;
}

.dt-tag a:hover {
  background: #00baa2;
  color: #FFF;
}

/*--------------------------------------------------------------
  ## Dt Resent Post
  --------------------------------------------------------------*/
/* Widget Resent Post */
.widget_recent_entries .resent-post {
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}

.widget_recent_entries .resent-post .post-thumbs {
  float: left;
  border-radius: 5px;
  overflow: hidden;
}

.widget_recent_entries .resent-post .post-content {
  margin-left: 90px;
  padding-bottom: 0;
  border: none;
}

.widget_recent_entries .resent-post .post-content h5 {
  font-size: 14px;
  font-size: 1em;
  margin-top: 0;
}

.widget_recent_entries .resent-post .post-content h5 a {
  color: #111111;
  line-height: 20px;
  line-height: 1.42857em;
  text-decoration: none;
  transition: all 0.3s ease;
  font-weight: 700;
}

.widget_recent_entries .resent-post .post-content h5 a:hover {
  color: #00baa2;
}

.widget_recent_entries .resent-post .post-content .post-meta {
  padding: 0;
  margin-left: 0;
  list-style: none;
}

.widget_recent_entries .resent-post .post-content .post-meta li {
  display: inline;
  padding-right: 10px;
}

.widget_recent_entries .resent-post .post-content .post-meta li a {
  color: #00baa2;
  text-decoration: none;
}

.widget_recent_entries .resent-post .post-content .post-meta li a i {
  padding-right: 5px;
  color: #000;
}

/*--------------------------------------------------------------
  ## Dt Archive
  --------------------------------------------------------------*/
/* Widget Dt Archives */
.widget_archive ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.widget_archive ul li {
  padding: 10px 0;
  border-bottom: 1px solid #ededed;
  position: relative;
}

.widget_archive ul li a {
  color: #6d6c6c;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.widget_archive ul li a span {
  padding-left: 5px;
}

.widget_archive ul li p {
  position: absolute;
  right: 0;
  top: 10px;
  transition: all 0.3s ease-in-out;
}

.widget_archive ul li:hover a {
  color: #00baa2;
}

.widget_archive ul li:hover p {
  color: #00baa2;
}
