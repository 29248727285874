html {
  font-size: 14px; }

body {
  @include font-smoothing;
  color: $color_text;
  font-family: $font_primary;
  font-size: 14px;
  overflow: hidden;
  overflow-y: scroll;
  line-height: 26px;
  color: $color_front; }

p {
  color: $color_text;
  font-size: 14px;
  line-height: 24px;
  font-family: $font_primary; }

a {
  transition: all 0.3s ease-in-out;
  text-decoration: none; }


.margin-right {
  margin-right: 20px; }

.section-padding {
  padding: 0 20px 0 15px; }

.burgar_menu {
  .margin-left {
    margin-left: 0; }

  .section-padding {
    padding-left: 0; } }

.burgar_menu {
  .menu_slide {
    background-color: transparent;
    height: 70px; } }



.section-title {
  h5 {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    font-weight: 300;
    letter-spacing: 2px;
    text-transform: uppercase; }

  h2 {
    text-transform: uppercase;
    color: #000;
    margin-top: 0;
    position: relative;
    margin-bottom: 15px;
    position: relative;
    display: inline-block;
    font-size: 36px;
    padding-bottom: 15px;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin-bottom: 30px;
    font-weight: 900;
    margin-bottom: 30px;

    &:after {
      position: absolute;
      content: ' ';
      background: $color_theme;
      width: 70px;
      height: 3px;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%); } }

  &.text-left {
    h2 {
      &:after {
        left: 0;
        transform: translateX(0); } } } }


.sub-title {
  color: #000;
  margin-bottom: 50px;
  line-height: 24px;
  font-size: 18px;
  font-style: italic; }

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: $color_rgba; }

.color-black {
  color: #000; }

.section-dark {
  .section-title {
    h2 {
      color: #FFF; }

    h5 {
      color: #a9a9a9; }

    p {
      color: #d2d2d2; } } }

h1,h2,h3,h4,h5 {
  color: #000;
  margin-top: 0;
  font-family: $font_header; }


/* Back To Top */
.return-to-top {
  position: fixed;
  bottom: 80px;
  right: 0px;
  background: $color_theme;
  width: 42px;
  height: 42px;
  text-decoration: none;
  opacity: 0;
  transition: all 0.3s ease;
  z-index: 1000;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;


  i {
    color: #fff;
    margin: 0;
    position: relative;
    left: 11px;
    top: 9px;
    font-size: 19px;
    transition: all 0.3s ease;
    margin-left: 3px; }

  &:hover {
    background: rgba(0, 0, 0, 0.9);

    i {
      color: #fff;
      top: 5px; } } }


.back-top {
  opacity: 1; }



/*=========  Page Loader  ==========*/

.loading-block {
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 1); }


.page-loaded .loading-block {
  opacity: 0;
  visibility: hidden;
  pointer-events: none; }


.loading-block .loading-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px; }

/**
 *
 * All animations must live in their own file
 * in the animations directory and be included
 * here.
 *
 */


@-webkit-keyframes ball-scale-multiple {
  0% {
    -webkit-transform: scale(0) {
            transform: scale(0); }
    opacity: 0; }
  5% {
    opacity: 1; }
  100% {
    -webkit-transform: scale(1) {
            transform: scale(1); }
    opacity: 0; } }


@keyframes ball-scale-multiple {
  0% {
    -webkit-transform: scale(0) {
            transform: scale(0); }
    opacity: 0; }
  5% {
    opacity: 1; }
  100% {
    -webkit-transform: scale(1) {
            transform: scale(1); }
    opacity: 0; } }


.ball-scale-multiple {
  position: relative;
  -webkit-transform: translateY(-30px) {
      -ms-transform: translateY(-30px) {
          transform: translateY(-30px); } } }

.ball-scale-multiple > div:nth-child(2) {
    -webkit-animation-delay: -0.4s {
            animation-delay: -0.4s; } }

.ball-scale-multiple > div:nth-child(3) {
    -webkit-animation-delay: -0.2s {
            animation-delay: -0.2s; } }

.ball-scale-multiple > div {
    background-color: #b4b4b4;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    margin: 2px;
    -webkit-animation-fill-mode: both {
            animation-fill-mode: both; }
    position: absolute;
    left: -30px;
    top: 0px;
    opacity: 0;
    margin: 0;
    width: 60px;
    height: 60px;
    -webkit-animation: ball-scale-multiple 1s 0s linear infinite {
            animation: ball-scale-multiple 1s 0s linear infinite; } }

.Switcher {
  background: $color_theme;
  text-align: center;

  &:before {
    display: none; }

  h5 {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #FFF;
    margin-bottom: 20px; } }

#Switcher__control {
  background-image: none;
  background: $color_theme;

  i {
    font-size: 20px;
    color: #FFF; } }

#colors {
  text-align: center;
  padding-left: 0;

  li {
    width: 70px;
    height: 40px;
    text-align: center;
    line-height: 37px;
    color: #FFF;
    border-radius: 30px;
    margin-bottom: 10px;

    &.light {
      background: #fff;
      color: #000; } } }
