#experience {
  padding: 100px 0;
  text-align: center; }

.bs-exprience {
  padding: 70px 0;
  background: $color_theme;

  h3 {
    color: #FFF;
    font-weight: 300;
    display: inline-block;
    margin-bottom: 0;
    margin-right: 30px;
    span {
      font-size: 30px;
      font-weight: 700; } }

  .gp-btn {
    display: inline-block;
    padding: 12px 30px;
    border-radius: 30px;

    &:hover {
      color: #000;

      &:before {
        background: #FFF; } } } }
