#about-me {
  padding-top: 100px;
  background: #f3f3f3; }

.my-photo {
  img {
    width: 100%; } }

.profile-info {
  padding-bottom: 15px;
  border-bottom: 2px solid $color_theme;
  margin-bottom: 20px;

  .profile-title {
    font-size: 30px;
    text-transform: uppercase;
    color: $color_theme;
    font-weight: 700;
    margin: 0; }

  .profile-position {
    font-size: 20px;
    margin: 0; } }

.profile-list {
  margin: 0 0 35px 0;
  padding: 0;
  list-style: none;

  li {
    margin-bottom: 15px;

    .title {
      width: 150px;
      float: left;
      font-size: 17px;
      font-weight: 800;
      margin-bottom: 0;
      color: #131313;

      span {
        float: right; } }

    .cont {
      color: #757575;
      font-size: 18px;
      margin-left: 15px; } } }


.social-link {
  padding: 20px;
  background: $color_theme;
  text-align: center;


  .profile-social-link {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      margin-right: 20px;

      a {
        color: #FFF;
        font-size: 20px; } } } }

.dwn-btn {
  padding: 13px 0;
  width: 200px;
  text-align: center;
  border: 1px solid #333;
  font-size: 15px;
  color: #333;
  display: inline-block;
  border-radius: 30px;
  font-weight: 600;

  i {
    margin-right: 10px;
    font-size: 20px;
    vertical-align: middle; }

  &:hover {
    background: #333;
    color: #FFF;
    text-decoration: none; } }

.hire-btn {
  margin-left: 20px;
  background: $color_theme;
  border-color: $color_theme;
  color: #FFF;

  &:hover {
    border-color: #333; } }
