#blog {
  padding: 100px 40px; }

.blog-post {
  background: #1e1c20;
  margin: 0 auto 20px;
  max-width: 400px;

  &.blog-post-light {
    background-color: transparent;
    border: 1px solid #f3f3f3;

    .blog-post-content {
      .entry-title {
        a {
          color: #333;

          &:hover {
            color: $color_theme; } } }

      p {
        color: #444; }

      .post-meta {
        li {
          a {
            i {
              color: #333; } } } } } }


  img {
    width: 100%; } }

.blog-post-content {
  padding: 20px;

  .entry-title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 20px;

    a {
      text-decoration: none;
      color: #FFF;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: $color_theme; } } } }

.post-meta {
  margin: 0 0 20px 0;
  padding: 0;
  list-style: none;

  li {
    display: inline-block;
    margin-right: 10px;

    a {
      color: #999;

      i {
        margin-right: 5px;
        color: #FFF; }

      &:hover {
        color: $color_theme;
        text-decoration: none; } } } }

.post-content {
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
  p {
    color: #f5f5f5; } }

.post-footer {
  margin-top: 10px;

  .tag {
    display: inline-block;

    span {
      margin-right: 5px;
      color: #FFF; }
    a {
      display: inline-block;
      margin-right: 5px;
      text-decoration: none;
      color: #bbb; } }

  .post-status {
    margin: 0;
    padding: 0;
    list-style: none;
    float: right;

    li {
      display: inline-block;
      margin-left: 10px;
      a {
        color: #bbb;
        text-decoration: none;

        i {
          margin-right: 5px; }

        &:hover {
          color: $color_theme; } } } } }

.read-more-btn {
  color: #000;
  display: block;


  &:hover, &:focus {
    color: $color_theme;
    text-decoration: none; } }

/*=========  Blog Single  ==========*/
#blog-single {
  padding: 100px 0;
  background: #f5f5f5; }

.blog-full {
  .blog-thumb {
    img {
      width: 100%; } }

  .post-meta {
    margin-top: 20px;
    margin-bottom: 10px;

    li {
      font-weight: 600;
      i {
        margin-right: 5px;
        font-size: 16px; }

      a {
        margin: 0 5px; } } } }

.blog-content {
  .news-title {
    color: #000;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 20px; }

  p {
    color: #222;
    font-weight: 400;
    margin-bottom: 20px; } }

.blockquote {
  background: rgba(0, 186, 162, 0.4);
  padding: 40px 20px;
  border-left: none;
  p {
    font-size: 20px;
    font-style: italic;
    font-weight: 400;
    margin-bottom: 0; } }

.comment-area {
  margin-top: 30px;

  h3 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 24px;
    margin: 20px 0; }
  .dt-comments {
    padding-left: 0; }

  ul {
    margin: 0;
    list-style: none; } }

.comment-content {
  background: #fff;
  padding: 15px 10px;
  margin-bottom: 15px;

  .avatar {
    height: 70px;
    width: 70px;
    border: 1px solid #222;
    padding: 3px;
    float: left;
    margin-right: 10px;
    img {
      width: 100%; } }

  .comment-header {
    .author {

      a {
        font-size: 16px;
        font-weight: 700;
        color: #000;
        text-transform: uppercase;
        margin-right: 20px;
        text-decoration: none;
        position: relative;

        &:after {
          position: absolute;
          content: '/';
          right: -15px; }

        &:hover {
          color: $color_theme; } } }

    .time-ago {
      a {
        font-size: 13px;
        color: #333;
        text-decoration: none;

        &:hover {
          color: $color_theme; } } } }

  .post-message {
    margin-left: 80px; }

  .reply {
    float: right;
    a {
      padding: 7px 15px;
      color: #FFF;
      background: #333;
      text-decoration: none;

      &:hover {
        background: $color_theme;
        color: #FFF; } } } }


.form-leave-comment {
  form {
    input, textarea {
      width: 100%;
      border: 1px solid #d4d4d4;
      margin-bottom: 15px;
      padding: 6px 15px;
      outline: none;

      &:focus {
        border-color: $color_theme; } }

    input {
      height: 50px; }

    textarea {
      height: 150px; } }

  .gp-btn {
    padding: 12px 40px;
    font-size: 16px;
    text-transform: uppercase; } }
