.countup {
  padding: 70px 0;
  background-size: cover;

  .section-title {
    h2 {
      color: #FFF; } } }

.counter-box {
  i {
    font-size: 50px;
    color: #FFF; }

  h2 {
    text-transform: uppercase;
    font-weight: 800;
    color: #FFF;
    font-size: 50px;
    margin-bottom: 0; }

  p {
    color: #f5f5f5; } }
