#side_menu {
	width: 300px;
	background: #000;
	position: fixed;
	top: 0;
	height: 100vh;
	padding: 40px 20px;
	z-index: 9999;
	overflow-y: auto; }

.cbp-spmenu-right {
	right: -300px; }

.cbp-spmenu-right.cbp-spmenu-open {
	right: 0; }

.cbp-spmenu, .cbp-spmenu-push {
    transition: all 0.3s ease; }

.cbp-spmenu-push-toleft {
    left: -300px; }


.share {
	position: relative;

	.social-share {
		position: absolute;
		margin: 0;
		padding: 0;
		list-style: none;
		left: 0;
		background: #000;
		top: 50px;
		transition: all 0.3s ease-in-out;
		opacity: 0;
		// width: 100%
		li {
			text-align: center;
			border-bottom: 1px solid #272727;
			display: block;

			&:last-child {
				border-bottom: none; }
			a {
				padding: 2px 7px;
				display: block;

				i {
					margin-right: 0; }

				&:hover {
					background: $color_theme;
					color: #FFF; } } } }

	&:hover {
		.social-share {
			opacity: 1;
			top: 35px; } } }

.about-me {
	// text-align: center

	img {
		border-radius: 50%;
		margin-bottom: 20px; } }

.my-details {
	h4 {
		color: $color_theme; }

	h5 {
		color: #999;
		margin-top: 0; }

	p {
		color: #777;
		margin-bottom: 40px; } }

.side-menu {
	margin-bottom: 30px;
	padding: 20px 0;
	list-style: none;
	text-align: center;
	border-top: 1px solid #333;
	border-bottom: 1px solid #333;

	li {

		a {
			color: #FFF;
			font-size: 14px;
			font-weight: 700;
			text-decoration: none;
			letter-spacing: 1px;
			display: inline-block;
			position: relative;
			text-transform: uppercase;
			line-height: 26px;

			&:after {
				position: absolute;
				content: '';
				background: $color_theme;
				height: 1px;
				width: 	0;
				left: 0;
				bottom: 0;
				transition: all 0.3s ease-in-out; }

			&:hover {
				color: $color_theme;
				background-color: transparent;

				&:after {
					width: 100%; } } } } }

.instagram-feed {
	h4 {
		color: #FFF;
		margin-bottom: 10px;
		position: relative;
		padding-bottom: 10px;
		margin-bottom: 20px;

		&:after {
			position: absolute;
			content: '';
			background: $color_theme;
			height: 3px;
			width: 	100px;
			left: 50%;
			bottom: 0;
			transform: translateX(-50%);
			transition: all 0.3s ease-in-out; } }

	a {
		display: inline-block;
		height: 70px;
		width: 70px;
		margin: 5px 4px;

		img {
			width: 100%; } } }
