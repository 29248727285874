#contact {
  padding: 100px 0;
  background: #f5f5f5;

  h2 {
    color: #000;
    margin-bottom: 40px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 700;
    position: relative;
    padding-bottom: 10px;
    margin-top: 0;

    &:after {
      position: absolute;
      content: '';
      left: 0;
      bottom: 0;
      width: 80px;
      height: 4px;
      background: $color_theme; } } }


.address-box {
  .info {
    color: #000;
    margin-bottom: 50px; } }


.address-info {
  margin: 0;
  padding: 0;
  list-style: none;

  h4 {
    margin-left: 50px;
    font-size: 18px;
    font-weight: 700;
    position: relative;
    padding-bottom: 7px;
    margin-bottom: 5px;

    &:after {
      position: absolute;
      content: '';
      height: 3px;
      width: 50px;
      left: 0;
      bottom: 0;
      background: $color_theme; } }

  li {
    padding: 5px 0;
    margin-bottom: 20px;

    p {
      margin-bottom: 0; }

    .info-icon {
      height: 40px;
      width: 40px;
      line-height: 43px;
      background: $color_theme;
      border-radius: 50%;
      float: left;
      text-align: center;

      i {
        color: #FFF;
        font-size: 18px; } }

    .info-details {
      margin-left: 50px;
      p {
        font-size: 16px; } } } }


#ajax-form {
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: 10px;
  line-height: 14px;
  color: #101010;
  margin: 0 auto; }

#ajax-form label {
  display: block;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 30px; }

#ajax-form input {
  position: relative;
  width: calc(100% - 40px);
  padding: 10px 15px;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 14px;
  line-height: 24px;
  border: none;
  color: #737373;
  -webkit-transition: border-bottom 0.3s, color 0.3s;
  transition: border-bottom 0.3s, color 0.3s;
  margin-bottom: 20px; }

#ajax-form textarea,
#ajax-form input {
  width: 100%;
  background: rgba(50,50,50,0.1);
  height: 45px;
  border: none;
  border-radius: 0;
  outline: none;
  box-shadow: none;
  color: #000;
  transition: all 0.1s ease-in-out;
  border: 1px solid transparent;

  &:focus, &:hover {
    color: #fff;
    border: 1px solid #066b5e; } }

#ajax-form textarea {
  padding-right: 20px;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 14px;
  line-height: 24px;
  height: 150px;
  border:none {}
  color: #737373;
  -webkit-transition: border-bottom 0.3s, color 0.3s;
  transition: border-bottom 0.3s, color 0.3s;
  margin-bottom: 15px;
  padding: 10px 20px; }

.error {
  font: 11px/22px 'Open Sans', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: none;
  color: #636363; }

#ajaxsuccess {
  background: #f2f2f2;
  color: #101010;
  height: 60px;
  display: none;
  padding-left: 10px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px; }

.text-align-center {
  text-align: center;
  padding-top: 10px; }

#ajax-form textarea:active,
#ajax-form input:active {
  color: #101010; }

#ajax-form textarea:active,
#ajax-form input:hover {
  color: #101010; }

#ajax-form textarea:focus,
#ajax-form input:focus {
    outline: none !important; }

#ajax-form .massage-two {
  textarea {
    height: 200px; } }

#ajax-form input:-ms-input-placeholder {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #999999; }

#ajax-form input::-moz-placeholder {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #999999; }

#ajax-form input:-moz-placeholder {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #999999; }

#ajax-form input::-webkit-input-placeholder {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #999999; }

#ajax-form textarea:-ms-input-placeholder {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #999999; }

#ajax-form textarea::-moz-placeholder {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #999999; }

#ajax-form textarea:-moz-placeholder {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #999999; }

#ajax-form textarea::-webkit-input-placeholder {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #999999; }

#ajax-form button {
  background: #323232;
  color: #fff;
  font-size: 14px;
  padding: 15px 25px;
  max-width: 300px;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 1px; }


#ajax-form button:hover {
  color: #fff; }

#ajax-form button:focus,
#ajax-form button:active {
  border: none;
  outline: none; }

.button-effect {
  min-width: 150px;
  max-width: 200px;
  display: block;
  margin: 0 auto;
  border: none;
  background: none;
  color: inherit;
  vertical-align: middle;
  cursor:pointer {}
  position: relative;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale; }

.button-effect:focus {
  outline: none; }

.button-effect > span {
  vertical-align: middle; }



#ajax-form button[type=submit].clicked>span, #ajax-form button[type=submit]>i {
  display: none; }


#map {
  .gmap3-area {
    margin-bottom: 0;
    height: 500px; } }
