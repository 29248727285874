/* Widget Dt Archives */
.widget_archive {
  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      padding: 10px 0;
      border-bottom: 1px solid #ededed;
      position: relative;
      a {
        color: #6d6c6c;
        text-decoration: none;
        transition: all 0.3s ease-in-out;

        span {
          padding-left: 5px; } }
      p {
        position: absolute;
        right: 0;
        top: 10px;
        transition: all 0.3s ease-in-out; }


      &:hover {
        a {
          color: $color-theme; }

        p {
          color: $color-theme; } } } } }



