a:focus {
  text-decoration: none;
  color: #FFF; }

.gp-bn-btn {
  position: relative;
  background-color: $color_theme;
  padding: 12px 25px;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  color: #FFF;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  letter-spacing: 1px;

  &:hover {
    background: #111;
    color: #FFF;
    text-decoration: none; } }

.gp-bn-active {
  background-color: transparent;
  border: 1px solid #fff;
  margin-right: 0;

  &:hover {
    border-color: transparent; } }



.gp-btn {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
  overflow: hidden;
  background: #e1e1e1;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  padding: 10px 20px;
  cursor: pointer;
  background: #000;
  color: #FFF;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-font-smoothing: antialiased;
  border: none;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $color_theme;
    transform: scaleY(0);
    transform-origin: 50% 0;
    transition-property: transform;
    transition-duration: 0.4s;
    transition-timing-function: ease-out; }

  &:hover, &:focus {
    color: #FFF;
    text-decoration: none;
    &:before {
      transform: scaleY(1);
      transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66); }

    &:after {
      width: 100%; } } }

.gp-btn-bg {
  background: #111;
  color: #FFF;

  &:after {
    background: $color_theme; }

  &:hover, &:focus {
    background: transparent;
    color: #FFF;
    text-decoration: none;

    &:after {
      width: 100%; } } }

.gp-btn-radius {
  border-radius: 5px; }

.gp-bn-btn-2 {
  background-color: transparent;
  color: #FFF;
  border: 2px solid #FFF; }
