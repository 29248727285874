#service {
  padding: 100px 0 40px;
  background: #1f1f1f; }

.service-item {
  // background: #FFF
  box-shadow: 0 15px 40px rgba(35,32,39,0.5);
  margin-bottom: 50px;
  cursor: pointer;
  max-width: 360px;
  margin: 0 auto 30px;

  .service-thumb {
    position: relative;

    img {
      width: 100%; }

    .service-header {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      background: rgba(0,0,0,0.75);

      overflow: hidden;

      &:before {
        position: absolute;
        top: 20px;
        right: 20px;
        bottom: 20px;
        left: 20px;
        border: 5px solid #fff;
        box-shadow: 0 0 0 30px rgba(255,255,255,0.2);
        content: '';
        opacity: 1;
        -webkit-transition: opacity 0.35s,
        -webkit-transform 0.35s {}
        transition: all 0.3s ease-in-out;
        // -webkit-transform: scale3d(1.4,1.4,1);
        transform: scale3d(1,1,1); }


      .service-title {
        position: absolute;
        bottom: 40px;
        left: 40px;
        transition: all 0.3s ease-in-out;

        h3 {
          margin-bottom: 0;
          color: #FFF;
          text-transform: uppercase;
          font-weight: 700;
          font-size: 22px;
          transform: translate(0px, 30px);
          transition: all 0.3s ease-in-out; }


        span {
          font-size: 16px;
          color: #FFF;
          transform: translate(0px, 30px);
          transition: all 0.5s ease-in-out;
          position: relative;
          display: block;
          padding-bottom: 10px;
          margin-bottom: 20px;

          &:after {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 0;
            height: 3px;
            width: 0px;
            background: $color_theme;
            content: '';

            transition: all 0.3s ease-in-out; } } }


      .service-content {
        opacity: 0;
        transform: translate(0px, 30px) scale(0);
        transition: all 0.7s ease-in-out;

        p {
          font-size: 16px;
          font-weight: 700;
          color: #FFF; } } } }

  &:hover {
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.12);
    .service-header {
      transform: translateZ(20px);
      &:before {
        border: 2px solid #FFF; }
      .service-title {

        h3 {
          transform: translate(0, -20px); }

        span {
          transform: translate(0, -20px);

          &:after {
            width: 100px; } } }



      .service-content {
        opacity: 1;
        transform: translate(0, -20px)  scale(1); } } } }

#service-two {
  margin-top: 80px; }

.feature-item-two {
  background: #383838;
  padding: 60px 25px 30px;
  position: relative;
  text-align: center;
  transition: all 0.3s ease-in-out;
  max-width: 400px;
  margin: 0 auto 70px;

  h3 {
    color: $color_theme;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600; }

  p {
    color: #f3f3f3; }

  .service-icon {
    height: 70px;
    width: 70px;
    border: 1px solid $color_theme;
    margin: 0 auto;
    position: absolute;
    top: -35px;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.15s ease-in-out;


    i {
      position: absolute;
      z-index: 100;
      font-size: 35px;
      color: #FFF;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }

    &:before {
      position: absolute;
      content: '';
      margin: auto;
      height: 60px;
      width: 60px;
      background: $color_theme;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      transition: all 0.15s ease-in-out; } }

  &:hover {
    background: #111;
    transform: translateY(-10px);
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.12);
    .service-icon {
      &:before {
        height: 69px;
        width: 69px; } }

    p {
      color: #FFF; } } }

/*=========  Service Four  ==========*/
#service-four {
  padding: 70px 0;

  .skill-bar {
    margin-top: 70px !important; } }

.feature-item-five {
  float: left;
  width: 180px;
  height: 180px;
  background: #f7f7f7;
  text-align: center;
  padding: 58px 0;
  transition: all 0.3s ease-in-out;

  &:nth-child(1), &:nth-child(3), &:nth-child(5) {
    background: #f7f7f7; }

  &:nth-child(2), &:nth-child(4), &:nth-child(6) {
    background: #eff4f3; }

  .fea-icon {
    margin-bottom: 15px;
    i {
      font-size: 30px; } }


  h4 {
    font-size: 16px;
    font-weight: 300;
    color: #1d1d1a; }

  &:hover {
    background: $color_theme;

    .fea-icon {
      i {
        color: #FFF; } }

    .content {
      h4 {
        color: #FFF; } } } }

.service-title {
  margin-bottom: 30px;
  h3 {
    font-size: 26px;
    text-transform: uppercase;
    font-weight: 700;
    color: #333;
    position: relative;
    padding-bottom: 10px;

    &:after {
      position: absolute;
      content: '';
      left: 0;
      bottom: 0;
      background: $color_theme;
      height: 2px;
      width: 50px; } } }

/*=========  Service-five  ==========*/
#service-five {
  margin-top: 50px;

  .feature-item-three {
    h3 {
      color: #FFF; }

    p {
      color: #cccccc; } } }

