#testimonial {
  padding: 100px 0;
  background-size: cover;
  background-position: center center;
  overflow: hidden;

  .section-title {
    margin-bottom: 50px;

    h5 {
      color: #FFF; }

    h2 {
      color: #FFF; } } }

.testimonial {
  position: relative;

  .tm-control {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    .tm-prev, .tm-next {
      height: 35px;
      width: 35px;
      border: 1px solid #FFF;
      text-align: center;
      line-height: 40px;
      transition: all 0.3s ease;
      position: absolute;
      top: 30%;
      z-index: 1111;
      cursor: pointer;

      i {
        font-size: 25px;
        color: #FFF; }

      &:hover {
        background: $color_theme;
        border-color: $color_theme; } }

    .tm-next {
      right: -35px; }

    .tm-prev {
      left: -35px; } }

  &:hover {
    .tm-control {
      opacity: 1; }

    .tm-next {
      right: 0; }

    .tm-prev {
      left: 0; } } }


.testimonial-slider {
  text-align: center; }

.testi-content {
  p {
    color: #FFF;
    font-size: 16px;
    letter-spacing: 0.5px;
    font-family: $font_header;
    width: 60%;
    margin: 0 auto;
    margin-bottom: 20px; }

  i {
    font-size: 40px;
    color: #f1f1f1; }

  h4 {
    color: $color_theme;
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 700; }

  span {
    color: #FFF;
    font-weight: 600;
    margin-bottom: 20px;
    display: block; }

  .avatar {
    height: 100px;
    width: 100px;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 20px;

    img {
      width: 100%;
      border-radius: 50%; } } }

.slider-pagination {
  text-align: center;
  margin-top: 25px;

  .swiper-pagination {
    position: relative; } }


.swiper-pagination {
  .swiper-pagination-bullet {
    width: 15px;
    height: 15px;
    margin: 0 3px;
    background: #565656;
    -webkit-backface-visibility: visible;
    transition: opacity .2s ease;
    border-radius: 0;


    &.swiper-pagination-bullet-active, &:hover {
      background: $color_theme; } } }


.testmonial-two {
  .testi-content {
    border: 1px solid #333;
    text-align: left;
    padding: 20px 20px 40px 20px;

    p {
      width: 100%;
      margin-bottom: 0; } }

  .client-details {
    text-align: left;
    margin-top: -30px;
    margin-left: 20px;
    .avatar {
      height: 100px;
      width: 100px;
      border: 5px solid rgba(255,255,255,.1);
      margin-bottom: 10px;

      img {
        width: 100%; } }

    h4 {
      color: $color_theme;
      font-size: 18px;
      margin-bottom: 0;
      font-weight: 600; }

    span {
      color: #FFF; } } }
