/* Dt Tags */
.dt-tag {
  a {
    color: #6d6c6c;
    border: 1px solid #d4d4d4;
    text-decoration: none;
    padding: 4px 8px;
    display: inline-block;
    margin-bottom: 10px;
    margin-right: 5px;
    transition: all 0.3s ease;

    &:hover {
      background: $color_theme;
      color: #FFF; } } }
