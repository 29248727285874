// Fonts
$font_primary: 'Lato', sans-serif;
$font_header: 'Raleway', sans-serif;
$font_sub_header: 'Montserrat', sans-serif;

// Color
$color_theme: #00baa2;
$color_front: #111;
$color_theme-2: #f89406;
$color_theme-3: #642d88;
$color_text: #6d6c6c;
$color_header: #2c3e50;
$color_rgba: rgba(53, 152, 219, 0.8);
