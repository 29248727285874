/* Custom Menu */
.widget {
  margin-bottom: 30px;

  .widget-title {
    position: relative;
    @include font-size(20);
    color: #111;
    margin-top: 0;
    margin-bottom: 40px;
    padding-bottom: 15px;
    text-transform: uppercase;
    font-weight: 700;

    &:after {
      content: '';
      position: absolute;
      width: 50px;
      height: 3px;
      left: 0;
      bottom: 0;
      background: $color_theme; } } }

.widget_nav_menu {

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      padding: 10px 0;
      border-bottom: 1px solid #ededed;

      &:last-child {
        border-bottom: none; }
      a {
        color: #6d6c6c;
        text-decoration: none;
        transition: all 0.3s ease;
        font-weight: 600;

        &:hover {
          color: $color_theme; } } } } }
