#brand {
  padding: 100px 0;
  background: $color_theme; }

.brand-thumb {
  border: 1px solid #FFF;
  text-align: center;
  background: rgba(255,255,255,0.5);
  img {
    width: 100%; } }
