#video {
  padding: 150px 0;
  text-align: center;

  p {
    color: #ccc;
    font-weight: 600;
    font-size: 16px; } }

.video-play-icon {
  display: block;
  margin: 0 auto;
  width: 60px;
  height: 60px;
  line-height: 20px;
  font-size: 6rem;
  background-color: $color_theme;
  border-radius: 50%;
  box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.2);

  &:focus {
    outline: none; } }

.how-it-works .video-play-icon {
  margin-top: 30px; }

.video-play-icon i {
  margin-left: 9px;
  font-size: 30px;
  color: #FFF; }

.intro-title {
  text-transform: uppercase;
  color: #FFF;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 15px; }

